.main-header {
  position: sticky;
  /* position: fixed; */
  top: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
}

.main-header .top-bg {
  height: 13px;
  background-color: #9926e5;
}

.main-header .header-bg {
  background: #ffffffba 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(10PX);
  -webkit-backdrop-filter: blur(12px);
  transition: 0.4s !important;
}

.main-header .header-bg .flex-twosec{
  display: flex;
}

.main-header .header-bg .header-navbar{
  display: flex;
  justify-content: center;
  padding: 9px 0px;
}
.main-header .header-navbar .navbar-collapse {
  margin: 0px 0px 0px -40px;
}
.header-navbar .navbar  .navbar-brand{
  margin: 0px;
}

.main-header .scrolled{
  background-color: #fff !important;
  transition: 0.4s  !important;
}

.main-header .sub-header .sub-header-content {
  position: absolute;
  width: 100%;
  top: -4px;
}


.main-header .sub-header .call-number {
  display: flex;
  justify-content: start;
  align-items: center;
}

.main-header .sub-header .call-number .call {
  background: #ffffff;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  margin-right: 10px;
}

.main-header .sub-header .call-number .call svg {
  font-size: 8px;
  text-align: center;
  padding: 0px 0px 8px 4px;
}
.main-header .sub-header {
  background: #9926e5 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 10px 10px;
  padding: 12px 0px;
  position: relative;
}

.main-header .sub-header .header-icons {
  display: flex;
  justify-content: end;
  margin-top: 1px;
}

.main-header .sub-header .header-icons .icon {
  margin-right: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-header .sub-header .header-icons .icon .social {
  width: 15px;
  height: 15px;
  filter: brightness(10);
}

.main-header .sub-header p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  color: #ffffff;
}

/* header-navbar section start */

.main-header .header-navbar .header-logo .logo {
  width: 110px;
  height: auto;
  position: absolute;
  left: -150px;
  bottom: 7px;
}

.main-header .header-navbar .nav-item {
  margin: 0% 35px;
  position: relative;
}

.main-header .header-navbar .nav-link:hover {
  color: #562278;
}



.main-header .header-navbar .nav-link.active {
  color: #562278;
  font-weight: bold;
}

.main-header .header-navbar .nav-link.active:after {
  border-top: 10px solid #e40813;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 120%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  z-index: -1;
}

.main-header .header-navbar .nav-link {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}
.main-header .navbar-expand-md .navbar-nav .dropdown-menu {
  position: absolute;
  border: 0px;
  background: transparent;
  padding: 0px;
}

.main-header .header-navbar .drop1 {
  width: 800px;
  /* width: max-content !important; */
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 18px;
  background-color: #fff;
  padding: 20px;
  position: absolute;
  top: 20px;
}

.main-header .header-navbar .drop5 {
  width: 655px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 18px;
  background-color: #fff;
  padding: 20px;
  position: absolute;
  top: 20px;
  right: 10%;
}

.main-header .header-navbar .drop2 {
  width: 600px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 18px;
  background-color: #fff;
  padding: 20px;
  position: absolute;
  top: 20px;
}
.main-header .header-navbar .drop3 {
  width: auto;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 18px;
  background-color: #fff;
  padding: 20px;
  position: absolute;
  top: 20px;
}
.main-header .header-navbar .drop4 {
  width: 510px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 18px;
  background-color: #fff;
  padding: 20px;
  position: absolute;
  top: 20px;
}

.main-header .header-navbar .dropdown-content .dropdown-heading p {
  font-size: 14px;
  font-weight: 600;
  color: #2a2a2a;
}

.main-header .header-navbar .dropdown-content .dropdown-heading p:after {
  content: "";
  display: block;
  width: 30px;
  border: 1px solid #00b8ff;
  margin-top: 1%;
}

.main-header .header-navbar .dropdown-content .sub-heading p {
  font-size: 16px;
  font-weight: 600;
  color: #2a2a2a;
  font-family: "Poppins", "sans-serif" !important;
}

.main-header .header-navbar .dropdown-item,
.main-header .header-navbar .dropdown-item:hover {
  padding: 0%;
  color: #2a2a2a;
  font-weight: 500;
  font-size: 12px;
  padding-bottom: 10px;
  background-color: transparent;
}

.main-header .header-navbar .dropdown-item:hover {
  color: #233f8c;
}


.main-header .header-navbar .bg-body-tertiary {
  background-color: none;
}

.main-header .offcanvas.offcanvas-end {
  /* height: 100vh; */
  width: 100%;
  transition: 0.5s ease-in-out;
}

.main-header .offcanvas.offcanvas-end .Offcanvas-bg_img {
  width: 100%;
  position: absolute;
  height: auto;
  z-index: -1;
}

.main-header .header-navbar nav button.btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #000000;
  transition: ease-in;
  margin-left: 36%;
}

.main-header .offcanvas.offcanvas-end .first-empty {
  height: 100vh;
  background: #562278 0% 0% no-repeat padding-box;
  opacity: 0.48;
}

.main-header .offcanvas.offcanvas-end .second-bg {
  height: 100vh;
  background: linear-gradient(-132deg, #562278, #22132d, #562278, #570c89);
  background-size: 400% 400%;
  animation: gradientBG 5s ease infinite;
}
@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



.main-header .offcanvas.offcanvas-end .last-content {
  height: 100vh;
  background: #ffffff40;
  backdrop-filter: blur(14px);
}

.main-header .offcanvas.offcanvas-end .offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.main-header .offcanvas.offcanvas-end .offcanvas-body {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  /* align-items: center; */
}

.main-header .offcanvas.offcanvas-end .offcanvas-body .footer-link p {
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  color: #2a2a2a;
}

.main-header .offcanvas.offcanvas-end .offcanvas-body .footer-link p:hover {
  font-weight: 600;
  font-size: 28px;
  color: #562278;
}
.main-header .offcanvas.offcanvas-end .offcanvas-body .footer-link li {
  list-style: none;
}

.main-header .offcanvas.offcanvas-end .offcanvas-body .footer-link .hr {
  border: 2px solid #00a8ff;
  margin: 5% auto;
  width: 40px;
}

.main-header .offcanvas.offcanvas-end button {
  border: none;
  background: none;
}

.main-header .offcanvas.offcanvas-end button svg {
  color: #233f8c;
  font-size: 30px;
}

.main-header .dropdown-toggle::after {
  display: none;
}

.main-header .nav-item.dropdown .angle-down-icon {
  position: absolute;
  right: -6px;
  font-size: 12px;
  bottom: 12px;
}

.main-header .header-navbar .dropdown-content .main-anti-infective {
  text-align: left;
  font: normal normal medium 12px/15px Livvic;
  letter-spacing: 0px;
  color: #2A2A2A;
  position: relative;
  opacity: 1;
}

.main-header .header-navbar .dropdown-content .anti-angle-down-icon {
  position: absolute;
  top: 24%;
  font-size: 10px;
  margin-left: 10px;
}

/* header-navbar section start */

@media (min-width: 768px) and (max-width: 992px) {

  .main-header .offcanvas.offcanvas-end .offcanvas-body .footer-link p {
    font-size: 20px;
}

  .main-header .offcanvas.offcanvas-end .offcanvas-body .footer-link p:hover {
    font-size: 25px;
}

  .main-header .header-navbar .drop3 {
    left: -50px;
}

  .main-header .header-bg .header-navbar {
    margin-left: 0%;
}

.main-header .header-navbar .navbar-collapse {
  margin: 0px -37px 0px 0px;
}

.main-header .header-navbar .nav-link {
  font-size: 11px;
  padding-right: 5px;
  padding-left: 5px;
}
  .main-header .header-navbar nav button.btn {
    padding: 2px 7px;
    font-size: 13px;
    margin-left: 7px;
}

.main-header .header-navbar .header-logo .logo {
  width: 65px;
  height: auto;
  left: -60px;
}
  .main-header .header-navbar .dropdown-content .sub-heading p {
    font-size: 14px;
  }
  .main-header .header-navbar .dropdown-item,
  .main-header .header-navbar .dropdown-item:hover {
    font-size: 11px;
  }
  .main-header .header-navbar .drop4 {
    left: -260px;
  }
  .main-header .header-navbar .drop1 {
    left: -92px;
    width: 728px;
}
  .main-header .header-navbar .drop2 {
    left: -240px;
    width: 760px;
  }
  .main-header .header-navbar .nav-item {
    margin: 0% 3px;
}

.main-header .nav-item.dropdown .angle-down-icon {
  right: -4px;
  font-size: 8px;
  bottom: 12px;
}

.main-header .navbar-expand-md .navbar-nav .dropdown-menu {
  left: -20px;
}

  .main-header .header-navbar .nav-link.active:after {
    border-top: 5px solid #e40813;
    top: 122%;
    width: 5px;
  }
  .main-header .header-navbar .drop5 {
    right: 0px;
}
.main-header .sub-header {
  width: 100%;
  margin: auto;
}
.main-header .sub-header p {
  font-size: 11px;
}
}

@media (min-width: 993px) and (max-width: 1023px) {

  .main-header .offcanvas.offcanvas-end .offcanvas-body .footer-link p:hover {
    font-size: 25px;
}
  .main-header .header-navbar .drop4 {
    left: -200px;
  }
  .main-header .header-navbar .drop1 {
    left: -150px;
  }
  .main-header .header-navbar .drop2 {
    left: -200px;
  }

  .main-header .header-navbar .nav-item {
    margin: 0% 8px;
  }
  .main-header .header-navbar .nav-link {
    font-size: 13px;
  }
  .main-header .header-navbar .nav-item {
    margin: 0% 6px;
}
  .main-header .sub-header {
    width: 85%;
    margin: auto;
  }
  .main-header .header-navbar .header-logo .logo {
    left: -88px;
    width: 80px;
}
  .main-header .sub-header p {
    font-size: 11px;
}
.main-header .header-navbar nav button.btn {
  padding: 0px 6px;
  margin-left: 10px;
}

.main-header .header-navbar .navbar-collapse {
  margin: 0px 0px 0px 0px;
}
}

@media (min-width: 1024px) and (max-width: 1200px) {

  .main-header .offcanvas.offcanvas-end .offcanvas-body .footer-link p {
    font-size: 22px;
}

  .main-header .header-navbar .navbar-collapse {
    margin: 0px 0px 0px 0px;
}
  .main-header .header-navbar .drop4 {
    left: -200px;
  }
  .main-header .header-navbar .drop1 {
    left: -150px;
  }
  .main-header .header-navbar .drop2 {
    left: -200px;
  }

  .main-header .header-navbar .nav-item {
    margin: 0% 8px;
  }
  .main-header .header-navbar .nav-link {
    font-size: 13px;
  }
  .main-header .header-navbar .nav-item {
    margin: 0% 6px;
}
  .main-header .sub-header {
    width: 85%;
    margin: auto;
  }
  .main-header .header-navbar .header-logo .logo {
    left: -85px;
    width: 80px;
}
  .main-header .sub-header p {
    font-size: 11px;
}
.main-header .header-navbar nav button.btn {
  padding: 0px 6px;
  margin-left: 10px;
}
}

@media (min-width: 1201px) and (max-width: 1400px) {

  .main-header .header-navbar .navbar-collapse {
    margin: 0px 0px 0px -5%;
}
  .main-header .header-navbar .drop4 {
    left: -170px;
  }
  .main-header .header-navbar .drop1 {
    left: -50px;
  }
  .main-header .header-navbar .drop2 {
    left: -70px;
  }
  .main-header .header-navbar .nav-item {
    margin: 0% 10px;
  }
  .main-header .sub-header {
    width: 85%;
    margin: auto;
  }
  .main-header .header-navbar .header-logo .logo {
    left: -120px;
    width: 100px;
}
  .main-header .header-navbar nav button.btn {
    padding: 0px 6px;
    margin-left: 10px;
  }
}


@media (min-width:1401px) and (max-width:1600px){

  .join-us-section .back-bg .first-sec .upload-img {
    width: 131px;
}
  .main-header .header-navbar .drop4 {
    left: -180px;
}
.main-header .header-navbar .nav-item {
  margin: 0% 21px;
}
.main-header .sub-header {
  width: 85%;
  margin: auto;
}
.main-header .header-navbar .header-logo .logo {
  left: -140px;
}
}

@media (min-width:1601px) and (max-width:1800px){
  .main-header .header-navbar .drop4 {
    left: -120px;
}
}

@media (min-width:1600px) and (max-width:2660px){
.main-header .header-navbar nav button.btn {
  margin-left: 0%;
}
}


/* sms lottie css */
.sms-lottie {
  position: fixed;
  bottom: 0;
  z-index: 999999;
  right: 0;
}
/* sms lottie css */