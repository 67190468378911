.announcement {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E6F8FF 100%) 0% 0% no-repeat padding-box;
    margin-top: 5%;
    padding-bottom: 5%;
}

.announcement .annual-content .image-holder {
    margin-top: 15%;
}

.announcement .annual-content .image-holder .announcement-img{
    width: 100%;
    height: 100%;
}

.announcement .annual-content .announcement-heading h1{
    font-size: 32px;
    font-weight: 600;
    color: #000000;
}

.main-more-info-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #0000000F;
    border-radius: 10px 10px 0px 0px;
    padding: 0px 20px;
    margin: 0% 10% 3% 4%;
    border-bottom: 2px solid #00A7E5;
    height: 90px;
}

.announcement .annual-content .slider-content .more-info-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.announcement .annual-content .slider-content .more-info-box .text-holder p {
    font-size: 16px;
    font-weight: 500;
    color: #2A2A2A;
    margin-bottom: 0;
}

.main-more-info-box #btn-size {
    padding: 3px 12px;
    width: 90px;
    text-align: center;
    box-shadow: none;
}

.announcement .annual-content .announcement-swiper .swiper-button-next:after, .announcement .annual-content .announcement-swiper  .swiper-button-prev:after {
    display: block;
  }

  
.announcement .annual-content .announcement-swiper .swiper-pagination{
    display: none;
  }


  .announcement .annual-content .announcement-swiper .swiper-button-next,
  .announcement .annual-content .announcement-swiper .swiper-button-prev {
  position: absolute;
  bottom: 60px;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.announcement .annual-content .announcement-swiper .swiper-button-next, .announcement .annual-content .announcement-swiper .swiper-rtl .swiper-button-prev {
    right: 10%;
    left: auto;
    top: 4%;
}

.announcement .annual-content .announcement-swiper .swiper-button-prev, .announcement .annual-content .announcement-swiper .swiper-rtl .swiper-button-next {
    right: 15%;
    left: auto;
    top: 4%;
}

.announcement .annual-content .announcement-swiper .swiper-button-next:after, .announcement .annual-content .announcement-swiper .swiper-button-prev:after {
    font-size: 9px;
    color: #ffffff;
    background: #000000;
    width: 24px;
    height: 24px;
    text-align: center;
    padding: 7px;
    border-radius: 50px;
}

.announcement .annual-content .announcement-swiper {
    padding-top: 10%;
}

.announcement .annual-content .main-slider-section {
    position: relative;
}

.announcement .annual-content .announcement-heading {
    position: absolute;
    left: 5%;
}

@media (min-width:768px) and (max-width:991px){

    .announcement .annual-content .announcement-swiper {
        margin-top: 5%;
    }

    .announcement .annual-content .announcement-heading h1 {
        font-size: 25px;
    }

    .announcement .annual-content .slider-content .more-info-box .text-holder {
        padding-right: 0px;
    }

    .announcement .annual-content .slider-content .more-info-box .text-holder p {
        font-size: 12px;
    }

    .announcement {
        margin-bottom: 10%;
    }

    .announcement .annual-content .image-holder {
        margin-top: 0%;
    }
    .announcement .annual-content .announcement-swiper .swiper-button-prev, .announcement .annual-content .announcement-swiper .swiper-rtl .swiper-button-next {
        top: 3%;
    }

    .announcement .annual-content .announcement-swiper .swiper-button-next, .announcement .annual-content .announcement-swiper .swiper-rtl .swiper-button-prev {
        top: 3%;
    }
}


@media (min-width:992px) and (max-width:1023px){

    .announcement .annual-content .image-holder {
        margin-top: 22%;
    }
    .announcement .annual-content .announcement-swiper {
        margin-top: 5%;
    }

    .announcement .annual-content .announcement-heading h1 {
        font-size: 25px;
    }

    .announcement .annual-content .slider-content .more-info-box .text-holder p {
        font-size: 12px;
    }

    .announcement {
        margin-bottom: 10%;
    }

    .main-more-info-box {
        height: 64px;
    }

    .announcement .annual-content .slider-content .more-info-box .text-holder{
        padding-right: 5px;
    }


    .announcement .annual-content .announcement-swiper .swiper-button-next, .announcement .annual-content .announcement-swiper .swiper-rtl .swiper-button-prev {
        top: 4%;
        right: 2%;
    }
    .announcement .annual-content .announcement-swiper .swiper-button-prev, .announcement .annual-content .announcement-swiper .swiper-rtl .swiper-button-next {
        right: 9%;
        top: 4%;
    }
    .announcement .annual-content .announcement-heading h1 {
        font-size: 22px;
    }
    
    .main-more-info-box {
        margin: 0% 3% 3% 5%;
    }

}


@media (min-width:1024px) and (max-width:1199px){
    .announcement .annual-content .slider-content .more-info-box .text-holder{
        padding-right: 5px;
    }

  
    .main-more-info-box {
        height: 64px;
    }
   
    .announcement .annual-content .announcement-swiper {
        margin-top: 5%;
    }

    .announcement .annual-content .announcement-heading h1 {
        font-size: 22px;
    }

    .announcement .annual-content .slider-content .more-info-box .text-holder p {
        font-size: 12px;
    }

    .announcement {
        margin-bottom: 10%;
    }

    .announcement .annual-content .image-holder {
        margin-top: 22%;
    }

    .announcement .annual-content .announcement-swiper .swiper-button-next{
        top: 4%;
        right: 2%;
    }

    .announcement .annual-content .announcement-swiper .swiper-button-prev {
        right: 8%;
    }

    .main-more-info-box {
        margin: 0% 3% 3% 5%;
    }
}

@media (min-width:1200px) and (max-width:1399px){

    .main-more-info-box {
        height: 76px;
    }

    .announcement .annual-content .announcement-swiper {
        margin-top: 5%;
    }

    .announcement .annual-content .announcement-heading h1 {
        font-size: 22px;
    }

    .announcement .annual-content .slider-content .more-info-box .text-holder p {
        font-size: 12px;
    }

    .announcement {
        margin-bottom: 10%;
    }

    .announcement .annual-content .image-holder {
        margin-top: 21%;
    }
    .announcement .annual-content .announcement-swiper .swiper-button-next, .announcement .annual-content .announcement-swiper .swiper-rtl .swiper-button-prev {
        top: 3%;
    }
    .announcement .annual-content .announcement-swiper .swiper-button-prev, .announcement .annual-content .announcement-swiper .swiper-rtl .swiper-button-next {
        right: 16%;
        top: 3%;
    }
}


