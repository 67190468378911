.main .play-icon {
    width: 90%;
    height: 90%;
    margin: auto;
}

@media (min-width: 1024px) and (max-width: 1199px) {
.main .play-icon {
    width: 75%;
    height: 75%;
}
}