.EHS-Sustainability {
  margin-bottom: 10%;
  margin-top: 5%;
}
#impact-bg{
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #E6F8FF 100%) 0% 0% no-repeat padding-box;
}

.EHS-Sustainability .EHS-content .text-holder h1 {
  font-size: 27px;
  font-weight: 500;
  color: #2a2a2a;
}

.EHS-Sustainability .EHS-content .text-holder p {
  font-size: 15px;
  font-weight: 500;
  color: #562278;
  text-align: justify;
}


.EHS-Sustainability .EHS-content .text-holder  {
  margin-top: 10%;
}

.EHS-Sustainability .EHS-content .img-holder {
  overflow: hidden;
  padding: 40px 30px;
}

.EHS-Sustainability .EHS-content .img-holder .EHS-img {
  width: 100%;
  height: 100%;
  transition: 0.3s;
}
.EHS-Sustainability .EHS-content .img-holder .EHS-img:hover{
  transform: scale(1.1);
}

@media (min-width: 768px) and (max-width: 991px) {
.EHS-Sustainability .EHS-content .text-holder h1 {
  font-size: 20px;
}
.EHS-Sustainability .EHS-content .text-holder p {
  font-size: 12px;
}

.EHS-Sustainability .EHS-content .img-holder {
  padding: 40px 17px;
}
}


@media (min-width: 992px) and (max-width: 1023px) {
  .EHS-Sustainability .EHS-content .text-holder p {
    font-size: 12px;
  }
  .EHS-Sustainability .EHS-content .text-holder h1 {
    font-size: 19px;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .EHS-Sustainability .EHS-content .text-holder p {
    font-size: 12px;
  }
  .EHS-Sustainability .EHS-content .text-holder h1 {
    font-size: 19px;
}
.EHS-Sustainability .EHS-content .img-holder {
  padding: 40px 17px;
} 
}

@media (min-width: 1200px) and (max-width: 1399px) {

  .EHS-Sustainability .EHS-content .img-holder {
    padding: 40px 20px;
} 

.EHS-Sustainability .EHS-content .text-holder h1 {
  font-size: 23px;
}
}