.main .Subscribe {
  margin-top: 5%;
  position: relative;
}

.main .Subscribe .subscribe-content {
  padding-bottom: 2%;
}

.main .Subscribe .subscribe-content .subscribe-box .box {
  width: 100%;
  height: 375px;
  background: linear-gradient(-155deg, #ba7ce3, #450253, #461765, #8d53b3);
  background-size: 400% 400%;
  animation: gradientBG 7s ease infinite;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.main .Subscribe .subscribe-content .subscribe-box .box .inner-box {
  background: #ffffff2e 0% 0% no-repeat padding-box;
  border-radius: 33px;
  opacity: 1;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 735px;
  height: 304px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main
  .Subscribe
  .subscribe-content
  .subscribe-box
  .box
  .inner-box
  .text-holder
  h5 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #ffffff;
}

.main
  .Subscribe
  .subscribe-content
  .subscribe-box
  .box
  .inner-box
  .text-holder
  p {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.main
  .Subscribe
  .subscribe-content
  .subscribe-box
  .box
  .inner-box
  .subscribe-box {
  width: 312px;
  height: 48px;
  display: flex;
  justify-content: center;
  margin: 0% auto;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 24px;
  margin-bottom: 5%;
}

.main
  .Subscribe
  .subscribe-content
  .subscribe-box
  .box
  .inner-box
  .subscribe-box
  .form-control {
  background: none;
  border: none;
}

.main
  .Subscribe
  .subscribe-content
  .subscribe-box
  .box
  .inner-box
  .subscribe-box
  .form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

.main
  .Subscribe
  .subscribe-content
  .subscribe-box
  .box
  .inner-box
  .subscribe-box
  .subscribe-btn {
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 18px;
  border: none;
}

/* page up css start */
/* 
.main .Subscribe .page-up {
  position: absolute;
  width: 100%;
  text-align: center;
  display: flex;
  top: 90%;
  justify-content: center;
  z-index: 1;
}
.main .Subscribe .page-up .page-up-circle .text .up-arrow {
  font-size: 29px;
}

.main .Subscribe .page-up .page-up-circle .text .up-arrow .arrow {
  animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
  cursor: pointer;
  height: 20px;
}

.main .Subscribe .page-up .page-up-circle .text .up-arrow .arrow :after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 100%;
  border-radius: 5px;
  background: black;
  transform: rotate(-90deg);
  transform-origin: 0% 100%;
}
@keyframes bounce {
  50% {
    transform: translateY(-15px);
  }
}

.main .Subscribe .page-up .page-up-circle {
  width: 102px;
  height: 102px;
  background: linear-gradient(to top, #B615CF 0%, #4D0D54 100%);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: end;
  border-radius: 50px;
  cursor: pointer;
} */

/* page up css end */

@media (min-width: 768px) and (max-width: 991px) {
  .main .Subscribe .subscribe-content .subscribe-box .box {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .main .Subscribe .subscribe-content .subscribe-box .box .inner-box {
    width: 100%;
    height: auto;
    padding: 5% 70px;
  }

  .main
    .Subscribe
    .subscribe-content
    .subscribe-box
    .box
    .inner-box
    .text-holder
    h5 {
    font-size: 25px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .main .Subscribe .subscribe-content .subscribe-box .box {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .main .Subscribe .subscribe-content .subscribe-box .box .inner-box {
    width: 100%;
    height: auto;
    padding: 5% 70px;
}

.main
  .Subscribe
  .subscribe-content
  .subscribe-box
  .box
  .inner-box
  .text-holder
  h5 {
    font-size: 25px;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .main .Subscribe .subscribe-content .subscribe-box .box {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .main .Subscribe .subscribe-content .subscribe-box .box .inner-box {
    width: 100%;
    height: auto;
    padding: 5% 70px;
}

.main
  .Subscribe
  .subscribe-content
  .subscribe-box
  .box
  .inner-box
  .text-holder
  h5 {
    font-size: 25px;
}
}
