.Privacypolicy .main-content-holder .text-holder {
    margin-bottom: 5%;
}

.Privacypolicy .main-content-holder .text-holder h1 {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 2%;
}


.Privacypolicy .main-content-holder .text-holder p {
    font-size: 14px;
    font-weight: 500;
    text-align: justify;
    color: #000000;
}

.Privacypolicy .nav-tabs .nav-link.active {
    border-bottom: 3px solid purple;
    font-size: 16px;
    font-weight: 700;
    color: #2A2A2A;
    border-top: none;
    border-right: none;
    border-left: none;
}

.Privacypolicy .main-content-holder .text-holder li::marker {
    font-size: 20px;
}

.Privacypolicy .main-content-holder .text-holder .list-style-none{
    list-style: none;
    padding-left: 0;
}

.Privacypolicy .nav-tabs .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: #2A2A2A;
    border: none;
    margin: 0px 40px;
}
.Privacypolicy .main-content-holder {
    margin-top: 2%;
    margin-bottom: 7%;
}

@media (min-width:768px) and (max-width:991px){
    .Privacypolicy .main-content-holder .text-holder h1 {
        font-size: 20px;
    }
    .Privacypolicy .main-content-holder .text-holder p {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
    }
}
