.main .QCIL .history-content .sub-heading-title h1 {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  color: #562278;
}

.main .QCIL .history-content .timeline-data {
  position: relative;
}

.main .QCIL .history-content .timeline-data .timeline-bg .timeline-bg-img {
  width: 100%;
  height: 100%;
}

.main .QCIL .history-content .timeline-data .timeline-bg {
  position: absolute;
  z-index: -1;
  top: 20%;
}

.main .QCIL .history-content .sub-heading-title {
  margin-top: 5%;
}

.main .QCIL .history-content .timeline-data .year-data h1 {
  text-align: left;
  font-size: 28px;
  font-weight: 500;
  color: #2a2a2a;
  margin-bottom: 5%;
}

.main .QCIL .history-content .timeline-data .year-data p {
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  color: #2a2a2a;
}

.main .QCIL .history-content .timeline-data .year-data p:hover {
  color: #562278;
}

.main .QCIL .history-content .timeline-data #data-1 {
  display: flex;
  position: relative;
}
.main .QCIL .history-content .timeline-data #data-1:after {
  display: inline-block;
  content: "";
  border-radius: 50px;
  height: 9px;
  width: 9px;
  margin-right: 0.5rem;
  background-color: #000000;
  position: absolute;
  right: 2px;
  bottom: 30%;
}

.main .QCIL .history-content .timeline-data #data-1:before {
  display: inline-block;
  content: "";
  border-radius: 50px;
  height: 8px;
  width: 8px;
  margin-right: 0.5rem;
  background: transparent linear-gradient(90deg, #009dff 0%, #3656ff 100%);
  position: absolute;
  right: 2px;
  bottom: -10%;
}

.main .QCIL .history-content .timeline-data #data-1 .dashred-line {
  display: block;
  content: "";
  position: absolute;
  width: 1px;
  border-right: 0px;
  border-style: dashed;
  height: 87px;
  left: 291px;
  top: 145px;
  color: #2a2a2a;
  border-width: thin;
  border-left: thin;
}

.main .QCIL .history-content .timeline-data #data-2 {
  margin-top: 20%;
  display: flex;
  margin-left: 10%;
  position: relative;
}

.main .QCIL .history-content .timeline-data #data-2:after {
  display: inline-block;
  content: "";
  border-radius: 50px;
  height: 9px;
  width: 9px;
  margin-right: 0.5rem;
  background-color: #000000;
  position: absolute;
  left: 84px;
  bottom: -10%;
}

.main .QCIL .history-content .timeline-data #data-2:before {
  display: inline-block;
  content: "";
  border-radius: 50px;
  height: 10px;
  width: 10px;
  margin-right: 0.5rem;
  background: transparent linear-gradient(90deg, #009dff 0%, #3656ff 100%);
  position: absolute;
  left: 30%;
  bottom: -61%;
}

.main .QCIL .history-content .timeline-data #data-2 .dashred-line {
  display: block;
  content: "";
  position: absolute;
  width: 1px;
  border-right: 0px;
  border-style: dashed;
  height: 100px;
  left: 88px;
  top: 242px;
  color: #2a2a2a;
  border-width: thin;
  border-left: thin;
}
.main .QCIL .history-content .timeline-data #data-3 {
  margin-top: 50%;
  display: flex;
  position: relative;
}

.main .QCIL .history-content .timeline-data #data-3:after {
  display: inline-block;
  content: "";
  border-radius: 50px;
  height: 9px;
  width: 9px;
  margin-right: 0.5rem;
  background-color: #000000;
  position: absolute;
  left: -30px;
  bottom: 22%;
}

.main .QCIL .history-content .timeline-data #data-3:before {
  display: inline-block;
  content: "";
  border-radius: 50px;
  height: 10px;
  width: 10px;
  margin-right: 0.5rem;
  background: transparent linear-gradient(90deg, #009dff 0%, #3656ff 100%);
  position: absolute;
  left: -10%;
  bottom: -20%;
}

.main .QCIL .history-content .timeline-data #data-3 .dashred-line {
  display: block;
  content: "";
  position: absolute;
  width: 1px;
  border-right: 0px;
  border-style: dashed;
  height: 87px;
  left: -26px;
  top: 164px;
  color: #2a2a2a;
  border-width: thin;
  border-left: thin;
}

.main .QCIL .history-content .timeline-data #data-4 {
  margin-top: 30%;
  display: flex;
  position: relative;
}

.main .QCIL .history-content .timeline-data #data-4:after {
  display: inline-block;
  content: "";
  border-radius: 50px;
  height: 9px;
  width: 9px;
  margin-right: 0.5rem;
  background-color: #000000;
  position: absolute;
  right: 0%;
  top: 15%;
}

.main .QCIL .history-content .timeline-data #data-4:before {
  display: inline-block;
  content: "";
  border-radius: 50px;
  height: 12px;
  width: 12px;
  margin-right: 0.5rem;
  background: transparent linear-gradient(90deg, #009dff 0%, #3656ff 100%);
  position: absolute;
  right: 0%;
  top: 60%;
}

.main .QCIL .history-content .timeline-data #data-4 .dashred-line {
  display: block;
  content: "";
  position: absolute;
  width: 1px;
  border-right: 0px;
  border-style: dashed;
  height: 87px;
  left: 402px;
  top: 45px;
  color: #2a2a2a;
  border-width: thin;
  border-left: thin;
}

.main .QCIL .history-content .timeline-data #data-5 {
  margin-top: 80%;
  display: flex;
  position: relative;
}

.main .QCIL .history-content .timeline-data #data-5:after {
  display: inline-block;
  content: "";
  border-radius: 50px;
  height: 9px;
  width: 9px;
  margin-right: 0.5rem;
  background-color: #000000;
  position: absolute;
  left: -60px;
  top: 50%;
}

.main .QCIL .history-content .timeline-data #data-5:before {
  display: inline-block;
  content: "";
  height: 14px;
  width: 14px;
  margin-right: 0.5rem;
  background: transparent linear-gradient(90deg, #009dff 0%, #3656ff 100%);
  position: absolute;
  left: -63px;
  top: 101%;
  border-radius: 50px;
}

.main .QCIL .history-content .timeline-data #data-5 .dashred-line {
  display: block;
  content: "";
  position: absolute;
  width: 1px;
  border-right: 0px;
  border-style: dashed;
  height: 103px;
  left: -56px;
  top: 116px;
  color: #2a2a2a;
  border-width: thin;
  border-left: thin;
}

.main .QCIL .history-content .timeline-data #data-6 {
  margin-top: 90%;
  display: flex;
  position: relative;
}

.main .QCIL .history-content .timeline-data #data-6:after {
  display: inline-block;
  content: "";
  border-radius: 50px;
  height: 9px;
  width: 9px;
  margin-right: 0.5rem;
  background-color: #000000;
  position: absolute;
  left: -27px;
  bottom: 20%;
}

.main .QCIL .history-content .timeline-data #data-6:before {
  display: inline-block;
  content: "";
  border-radius: 50px;
  height: 17px;
  width: 17px;
  margin-right: 0.5rem;
  background: transparent linear-gradient(90deg, #009dff 0%, #3656ff 100%);
  position: absolute;
  left: -10%;
  bottom: -31%;
}

.main .QCIL .history-content .timeline-data #data-6 .dashred-line {
  display: block;
  content: "";
  position: absolute;
  width: 1px;
  border-right: 0px;
  border-style: dashed;
  height: 98px;
  left: -23px;
  top: 78%;
  color: #2a2a2a;
  border-width: thin;
  border-left: thin;
}
.main .QCIL .history-content .timeline-data #data-7 {
  display: flex;
  position: relative;
}
.main .QCIL .history-content .timeline-data #data-7:after {
  display: inline-block;
  content: "";
  border-radius: 0.375rem;
  height: 9px;
  width: 9px;
  margin-right: 0.5rem;
  background-color: #000000;
  position: absolute;
  left: 131px;
  top: 7%;
}

.main .QCIL .history-content .timeline-data #data-7:before {
  display: inline-block;
  content: "";
  border-radius: 50px;
  height: 13px;
  width: 13px;
  margin-right: 0.5rem;
  background: transparent linear-gradient(90deg, #009dff 0%, #3656ff 100%);
  position: absolute;
  left: 131px;
  top: -33%;
}

.main .QCIL .history-content .timeline-data #data-7 .dashred-line {
  display: block;
  content: "";
  position: absolute;
  width: 1px;
  border-right: 0px;
  border-style: dashed;
  height: 80px;
  left: 136px;
  bottom: 90%;
  color: #2a2a2a;
  border-width: thin;
  border-left: thin;
}

.main .QCIL .history-content .timeline-data .year-data .year-list-box .box {
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 5px;
}

.main
  .QCIL
  .history-content
  .timeline-data
  .year-data
  .year-list-box
  .box
  .red-box {
  color: #e0051d;
  font-size: 7px;
}

.main
  .QCIL
  .history-content
  .timeline-data
  .year-data
  .year-list-box
  .purple-box {
  background: #562278;
  width: 6px;
  height: 6px;
}

.main .QCIL .history-content .timeline-data #data-1:hover .purple-box {
  height: 80px;
  transition: all 0.3s ease;
}
.main .QCIL .history-content .timeline-data #data-2:hover .purple-box {
  height: 80px;
  transition: all 0.3s ease;
}
.main .QCIL .history-content .timeline-data #data-3:hover .purple-box {
  height: 80px;
  transition: all 0.3s ease;
}
.main .QCIL .history-content .timeline-data #data-4:hover .purple-box {
  height: 80px;
  transition: all 0.3s ease;
}
.main .QCIL .history-content .timeline-data #data-5:hover .purple-box {
  height: 80px;
  transition: all 0.3s ease;
}
.main .QCIL .history-content .timeline-data #data-6:hover .purple-box {
  height: 80px;
  transition: all 0.3s ease;
}
.main .QCIL .history-content .timeline-data #data-7:hover .purple-box {
  height: 80px;
  transition: all 0.3s ease;
}

.main .QCIL .history-content .timeline-data .year-data .year-list-box {
  margin-right: 10px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .main .QCIL .history-content .timeline-data .year-data p {
    font-size: 9px;
  }

  .main .QCIL .history-content .timeline-data .year-data h1 {
    font-size: 20px;
  }

  .main .QCIL .history-content .sub-heading-title h1 {
    font-size: 20px;
  }

  .main .QCIL .history-content .timeline-data #data-7 {
    margin-top: 35%;
  }

  .main .QCIL .history-content .timeline-data #data-6 {
    margin-top: 0%;
}

.main .QCIL .history-content .timeline-data #data-3 {
  margin-top: 40%;
}

.main .QCIL .history-content .timeline-data #data-5 {
  margin-top: 55%;
}

.main .QCIL .history-content .timeline-data #data-1:after {
  right: 36px;
  height: 6px;
  width: 6px;
}

.main .QCIL .history-content .timeline-data #data-1:before {
  right: 17%;
  bottom: -19%;
  height: 6px;
  width: 6px;
}

.main .QCIL .history-content .timeline-data #data-1 .dashred-line {
  height: 62px;
  left: 78%;
  top: 98px;
}

.main .QCIL .history-content .timeline-data #data-2:after {
  left: 60px;
  height: 6px;
  width: 6px;
}

.main .QCIL .history-content .timeline-data #data-2:before {
  bottom: -54%;
  height: 8px;
  width: 8px;
}

.main .QCIL .history-content .timeline-data #data-2 .dashred-line {
  height: 53px;
  left: 62px;
  top: 110%;
}

.main .QCIL .history-content .timeline-data #data-3:after {
  left: -25%;
  bottom: 36%;
  height: 6px;
  width: 6px;
}

.main .QCIL .history-content .timeline-data #data-3:before {
  left: -55px;
  bottom: -33%;
  width: 8px;
  height: 8px;
}

.main .QCIL .history-content .timeline-data #data-3 .dashred-line {
  height: 88px;
  left: -52px;
  top: 91px;
}

.main .QCIL .history-content .timeline-data #data-4:after {
  left: 164px;
  top: -5%;
  height: 6px;
  width: 6px;
}

.main .QCIL .history-content .timeline-data #data-4:before {
  right: 35%;
  top: 55%;
  width: 8px;
  height: 8px;
}

.main .QCIL .history-content .timeline-data #data-4 .dashred-line {
  height: 80px;
  left: 60%;
  top: -1px;
}

.main .QCIL .history-content .timeline-data #data-5:after {
  left: -19%;
  top: 35%;
  height: 6px;
  width: 6px;
}

.main .QCIL .history-content .timeline-data #data-5:before {
  height: 9px;
  width: 9px;
  left: -20%;
  top: 121%;
}

.main .QCIL .history-content .timeline-data #data-5 .dashred-line {
  height: 116px;
  left: -39px;
  top: 55px;
}

.main .QCIL .history-content .timeline-data #data-7:before {
  height: 9px;
  width: 9px;
  left: 22%;
  top: -91%;
}

.main .QCIL .history-content .timeline-data #data-7:after {
  left: 89px;
  top: 4%;
  height: 6px;
  width: 6px;
}

.main .QCIL .history-content .timeline-data #data-7 .dashred-line {
  height: 124px;
  left: 23%;
  bottom: 94%;
}

.main .QCIL .history-content .timeline-data #data-6:after {
  left: 262px;
  bottom: 73%;
  height: 6px;
  width: 6px;
}

.main .QCIL .history-content .timeline-data #data-6 .dashred-line {
  height: 61px;
  left: 122%;
  top: -21%;
}

.main .QCIL .history-content .timeline-data #data-6:before {
  height: 8px;
  width: 8px;
  left: 121%;
  bottom: 122%;
}
}

@media (min-width: 992px) and (max-width: 1023px) {
  .main .QCIL .history-content .timeline-data #data-3:after {
    left: -18px;
    bottom: 21%;
    height: 7px;
    width: 7px;
}

.main .QCIL .history-content .timeline-data #data-4:after {
  height: 7px;
  width: 7px;
  right: 4px;
  top: 17%;
}

.main .QCIL .history-content .timeline-data #data-2:after {
  left: 59px;
  bottom: -8%;
  height: 7px;
  width: 7px;
}

.main .QCIL .history-content .timeline-data #data-1:after {
  height: 7px;
  width: 7px;
  right: 3px;
}
  .main .QCIL .history-content .timeline-data .year-data p {
    font-size: 12px;
  }

  .main .QCIL .history-content .timeline-data .year-data h1 {
    font-size: 20px;
  }

  .main .QCIL .history-content .sub-heading-title h1 {
    font-size: 20px;
  }

  .main .QCIL .history-content .timeline-data #data-1 .dashred-line {
    height: 53px;
    left: 93%;
    top: 120px;
  }

  .main .QCIL .history-content .timeline-data #data-1:before {
    right: 3px;
    bottom: -3%;
    height: 7px;
    width: 7px;
}

.main .QCIL .history-content .timeline-data #data-2:before {
  left: 58px;
  bottom: -51%;
  height: 8px;
  width: 8px;
}

  .main .QCIL .history-content .timeline-data #data-2 .dashred-line {
    height: 70px;
    left: 62px;
    top: 184px;
  }

  .main .QCIL .history-content .timeline-data #data-3:before {
    left: -18px;
    bottom: -15%;
    height: 8px;
    width: 8px;
}

  .main .QCIL .history-content .timeline-data #data-3 .dashred-line {
    height: 52px;
    left: -15px;
    top: 139px;
}

  .main .QCIL .history-content .timeline-data #data-4 {
    margin-top: 13%;
  }

  .main .QCIL .history-content .timeline-data #data-4:before {
    top: 84%;
    height: 9px;
    width: 9px;
    right: 3px;
}

.main .QCIL .history-content .timeline-data #data-5:after {
  height: 7px;
  width: 7px;
  left: -41px;
  top: 53%;
}

  .main .QCIL .history-content .timeline-data #data-4 .dashred-line {
    height: 107px;
    left: 280px;
    top: 38px;
}

.main .QCIL .history-content .timeline-data #data-5 .dashred-line {
  height: 59px;
  left: -38px;
  top: 100px;
}

  .main .QCIL .history-content .timeline-data #data-5:before {
    left: -42px;
    height: 10px;
    width: 10px;
    top: 91%;
}

.main .QCIL .history-content .timeline-data #data-7:before {
  left: 25%;
  top: -41%;
  height: 11px;
  width: 11px;
}

.main .QCIL .history-content .timeline-data #data-7:after {
  height: 7px;
  width: 7px;
  left: 96px;
}

.main .QCIL .history-content .timeline-data #data-7 .dashred-line {
  height: 72px;
  left: 99px;
  bottom: 93%;
}

.main .QCIL .history-content .timeline-data #data-6:after {
  height: 7px;
  width: 7px;
  left: -19px;
}

.main .QCIL .history-content .timeline-data #data-6 .dashred-line {
  height: 57px;
  left: -16px;
  top: 78%;
}

  .main .QCIL .history-content .timeline-data #data-6:before {
    bottom: -18%;
    width: 12px;
    height: 12px;
    left: -21px;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .main .QCIL .history-content .timeline-data .year-data p {
    font-size: 10px;
  }

  .main .QCIL .history-content .timeline-data #data-1:before {
    bottom: -7%;
  }

  .main .QCIL .history-content .timeline-data #data-1 .dashred-line {
    height: 50px;
    left: 93%;
    top: 114px;
  }

  .main .QCIL .history-content .timeline-data #data-2:before {
    bottom: -59%;
    height: 9px;
    width: 9px;
}

.main .QCIL .history-content .timeline-data #data-3:before {
  height: 9px;
  width: 9px;
}

  .main .QCIL .history-content .timeline-data #data-2 .dashred-line {
    height: 73px;
    left: 62px;
    top: 176px;
}

.main .QCIL .history-content .timeline-data #data-4:before {
  height: 10px;
  width: 10px;
  top: 61%;
}

.main .QCIL .history-content .timeline-data #data-2:after {
  left: 58px;
}

.main .QCIL .history-content .timeline-data #data-7:after {
  left: 95px;
}

.main .QCIL .history-content .timeline-data #data-3:after {
  left: -22px;
}
  .main .QCIL .history-content .timeline-data #data-3 .dashred-line {
    height: 60px;
    left: -18px;
    top: 125px;
  }

  .main .QCIL .history-content .timeline-data #data-6:after {
    left: -19px;
}

  .main .QCIL .history-content .timeline-data #data-4 .dashred-line {
    height: 66px;
    left: 282px;
    top: 34px;
}

.main .QCIL .history-content .timeline-data #data-6:before {
  height: 12px;
  width: 12px;
  left: -20px;
  bottom: -30%;
}

.main .QCIL .history-content .timeline-data #data-5:before {
  height: 11px;
  width: 11px;
  left: -43px;
  top: 101%;
}

.main .QCIL .history-content .timeline-data #data-7 .dashred-line {
  height: 53px;
  left: 99px;
  bottom: 92%;
}

  .main .QCIL .history-content .timeline-data #data-5 .dashred-line {
    height: 68px;
    left: -37px;
    top: 95px;
}

.main .QCIL .history-content .timeline-data #data-5:after {
  left: -42px;
  top: 52%;
}

  .main .QCIL .history-content .timeline-data #data-5 .dashred-line {
    height: 68px;
    left: -38px;
    top: 94px;
  }

  .main .QCIL .history-content .timeline-data #data-7:before {
    height: 11px;
    width: 11px;
    left: 94px;
    top: -33%;
}

.main .QCIL .history-content .timeline-data #data-6 .dashred-line {
  height: 72px;
  left: -7%;
  top: 78%;
}
}

@media (min-width: 1200px) and (max-width: 1399px) {

  .main .QCIL .history-content .timeline-data #data-2:after {
    height: 7px;
    width: 7px;
    left: 98px;
}
.main .QCIL .history-content .timeline-data #data-1:after {
  bottom: 45%;
  height: 7px;
  width: 7px;
  right: 3px;
}

  .main .QCIL .history-content .timeline-data #data-1 .dashred-line {
    height: 95px;
    left: 246px;
    top: 118px;
}
  .main .QCIL .history-content .timeline-data #data-1:before {
    right: 2px;
    bottom: -2%;
  }

  /* .main .QCIL .history-content .timeline-data #data-2 .dashred-line {
    height: 70px;
    left: 75px;
    top: 240px;
  } */

  .main .QCIL .history-content .timeline-data #data-2 .dashred-line {
    height: 53px;
    left: 101px;
    top: 240px;
}

  /* .main .QCIL .history-content .timeline-data #data-2:before {
    left: 31%;
    bottom: -48%;
  } */

  .main .QCIL .history-content .timeline-data #data-2:before {
    left: 97px;
    bottom: -39%;
    height: 9px;
    width: 9px;
}

.main .QCIL .history-content .timeline-data #data-3:after {
  left: 2px;
  height: 7px;
  width: 7px;
}

  /* .main .QCIL .history-content .timeline-data #data-3 .dashred-line {
    height: 70px;
    left: -23px;
    top: 164px;
  } */

  .main .QCIL .history-content .timeline-data #data-3 .dashred-line {
    height: 80px;
    left: 5px;
    top: 170px;
}

  /* .main .QCIL .history-content .timeline-data #data-3:before {
    left: -10%;
    bottom: -12%;
  } */

  .main .QCIL .history-content .timeline-data #data-3:before {
    left: 1px;
    bottom: -19%;
    height: 9px;
    width: 9px;
}

.main .QCIL .history-content .timeline-data #data-4 .dashred-line {
  height: 66px;
  left: 342px;
  top: 38px;
}

.main .QCIL .history-content .timeline-data #data-4:after {
  height: 7px;
  width: 7px;
  right: 2px;
}

.main .QCIL .history-content .timeline-data #data-4:before {
  top: 48%;
  height: 11px;
  width: 11px;
}

.main .QCIL .history-content .timeline-data #data-7:before {
  top: -47%;
  left: 113px;
  height: 12px;
  width: 12px;
}

.main .QCIL .history-content .timeline-data #data-7:after {
  height: 7px;
  width: 7px;
  left: 115px;
  top: 9%;
}

  .main .QCIL .history-content .timeline-data #data-7 .dashred-line {
    height: 109px;
    bottom: 91%;
    left: 118px;
}
.main .QCIL .history-content .timeline-data #data-5 .dashred-line {
  height: 60px;
  left: -53px;
}

.main .QCIL .history-content .timeline-data #data-5:before {
  top: 81%;
  left: -59px;
  height: 12px;
  width: 12px;
}

.main .QCIL .history-content .timeline-data #data-5:after {
  height: 7px;
  width: 7px;
  left: -56px;
  top: 50%;
}

.main .QCIL .history-content .timeline-data #data-6:after {
  bottom: 34%;
  left: -21px;
  height: 7px;
  width: 7px;
}

  .main .QCIL .history-content .timeline-data #data-6 .dashred-line {
    height: 77px;
    left: -7%;
    top: 66%;
  }

  .main .QCIL .history-content .timeline-data #data-6:before {
    left: -25px;
    bottom: -9%;
    height: 15px;
    width: 15px;
}
}
