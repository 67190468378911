.main .Worth {
  position: relative;
  width: 100%;
  height: 100%;
}

.main .Worth .Worth-content .bg-img .bg {
  width: 100%;
  height: 674px;
}

.main .Worth .Worth-content .content-holder {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.main
  .Worth
  .Worth-content
  .content-holder
  .inner-content
  .worth-img-bg
  .img-holder {
    padding: 15% 8%;
}


.main
  .Worth
  .Worth-content
  .content-holder
  .inner-content
  .worth-img-bg
  .bg-img-holder
  .worth-bg-img {
  width: 100%;
}
.main
  .Worth
  .Worth-content
  .content-holder
  .inner-content
  .img-holder
  .worth-img:hover {
  transform: scale(1.1);
}

.main
  .Worth
  .Worth-content
  .content-holder
  .inner-content
  .img-holder
  .worth-img {
  width: 100%;
  transition: 0.3s;
}

.main .Worth .Worth-content .content-holder .inner-content {
  margin: 5% 0%;
}

.main .Worth .Worth-content .content-holder .inner-content .text-holder {
  padding-top: 20%;
}

.main .Worth .Worth-content .content-holder .inner-content .text-holder h5 {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  text-align: justify;
}

.main .Worth .Worth-content .content-holder .inner-content .text-holder h1 {
  font-size: 34px;
  font-weight: 600;
  color: #000000;
  text-align: justify;
  margin-bottom: 5%;
}

.main .Worth .Worth-content .content-holder .inner-content .text-holder p {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  text-align: justify;
  margin-bottom: 5%;
}

/* .main
  .Worth
  .Worth-content
  .content-holder
  .inner-content */
  /* .read-more
  .read-more-btn {
  background: #562278;
  border: none;
  border-radius: 19px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  text-align: justify;
  border: 1px solid #562278;
  box-shadow: 0px 1px 5px 0px #9926e5;
} */
/* 
.main
  .Worth
  .Worth-content
  .content-holder
  .inner-content */
  /* .read-more
  .read-more-btn:hover {
  background: #ffffff;
  border: 1px solid #562278;
  border-radius: 19px;
  color: #000000;
} */

@media (min-width: 768px) and (max-width: 991px) {
  .main
    .Worth
    .Worth-content
    .content-holder
    .inner-content
    .img-holder
    .worth-img {
    height: auto;
    width: 100%;
  }

  .purple-read-more
.purple-read-more-btn {
    font-size: 10px;
}

  .main .Worth .Worth-content .content-holder .inner-content .text-holder {
    padding-top: 5%;
  }

  .main .Worth .Worth-content .content-holder .inner-content .text-holder p {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
}

  .main .Worth .Worth-content .content-holder .inner-content .text-holder h1 {
    font-size: 25px;
  }

  .main .Worth .Worth-content .content-holder .inner-content .text-holder h5 {
    font-size: 15px;
  }

  .main .Worth .Worth-content .bg-img .bg {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .main
    .Worth
    .Worth-content
    .content-holder
    .inner-content
    .img-holder
    .worth-img {
    height: auto;
    width: 100%;
  }

  .main .Worth .Worth-content .content-holder .inner-content .text-holder {
    padding-top: 5%;
  }

  .main .Worth .Worth-content .bg-img .bg {
    width: 100%;
    height: auto;
  }

  .main .Worth .Worth-content .content-holder .inner-content .text-holder h1 {
    font-size: 25px;
  }

  .main .Worth .Worth-content .content-holder .inner-content .text-holder h5 {
    font-size: 15px;
  }
  .main .Worth .Worth-content .content-holder .inner-content .text-holder p {
    font-size: 12px;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .main
    .Worth
    .Worth-content
    .content-holder
    .inner-content
    .img-holder
    .worth-img {
    height: auto;
    width: 100%;
  }

 

  .main .Worth .Worth-content .bg-img .bg {
    width: 100%;
    height: auto;
  }

  .main .Worth .Worth-content .content-holder .inner-content .text-holder {
    padding-top: 5%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
.main
  .Worth
  .Worth-content
  .content-holder
  .inner-content
  .worth-img-bg
  .img-holder {
    padding: 25% 6%;
}
}