.main .Successlottie-icon {
  width: 150px;
  height: 150px;
  cursor: pointer;
  border-radius: 50px;
  margin: 0 auto;
}
  
  
  @media (min-width: 768px) and (max-width: 991px) {
    .main .Successlottie-icon {
      width: 60px;
      height: 60px;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1023px) {
    .main .Successlottie-icon {
      width: 60px;
      height: 60px;
    }
  }
  
  @media (min-width: 1024px) and (max-width: 1199px) {
   
  }
  
  @media (min-width: 1200px) and (max-width: 1399px) {
  
    }
  