/*without swiper static css start */

.main .media .media-content .headeing .swiper-heading {
  text-align: left;
}

.main .media .media-content .headeing .swiper-heading h5 {
  font-size: 22px;
  font-weight: 500;
  color: #000000;
}
.main .media .media-content .headeing .swiper-heading h1 {
  font-size: 30px;
  font-weight: 700;
  color: #010101;
}

.main .media .media-content .headeing {
  position: relative;
  /* margin: auto 9%; */
}

/* .main .media .media-content .headeing  */
/* .main-img {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5%;
} */

/* .main .media .media-content .headeing  */
/* .main-img .swiper-main-img {
  width: 100%;
  height: 344px;
  transition: 0.3s;
} */

/* .main .media .media-content .headeing  */
/* .main-img:hover .swiper-main-img {
  transform: scale(1.2);
} */

/* .main .media .media-content .headeing */
 /* .main-img .overlay {
  background-image: linear-gradient(
    180deg,
    rgb(0 0 0 / 45%) 0%,
    #00000066 100%
  );
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  border-radius: 5px;
} */

/* .main .media .media-content .headeing */
 /* .main-img .overlay .main-text-holder {
  display: flex;
  justify-content: space-between;
  align-items: end;
  height: 90%;
  margin: auto 5%;
} */

/* .main
  .media
  .media-content
  .headeing */
  /* .main-img
  .overlay
  .main-text-holder
  .text-holder
  h1 {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
} */

/* .main
  .media
  .media-content
  .headeing */
  /* .main-img
  .overlay
  .main-text-holder
  .text-holder
  p {
  font-size: 12px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 0;
} */

/* .main
  .media
  .media-content
  .headeing
  .main-img
  .overlay 
  .read-more-btn {
  width: 36px;
  height: 36px;
}

.main
  .media
  .media-content
  .headeing
  .main-img
  .overlay 
  button {
  background: none;
  border: none;
} */

/* .main .media .media-content .headeing .main-img */
 /* .overlay .date-holder {
  background: #ffffff36 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 0.8;
  width: 80px;
  margin-bottom: 0;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1%;
  margin-top: 1%;
} */

/* .main .media .media-content .headeing */
 /* .main-img .overlay .date-holder p {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 0;
  color: #ffffff;
} */

/*without swiper static css end */

/* swiper css start */

.main
  .media
  .media-content
  .swiper-section{
    margin-top: 5%;
  }

.main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .cards
  .card {
  height: 100%;
  padding: 2% 2%;
  background: #f3f6ff00 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
}

.main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .cards
  .card
  .media-card-img {
  overflow: hidden;
  border-radius: 5px;
}

.main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .cards
  .card
  .media-card-img
  .card-img-top {
  transition: 0.3s;
}

.main
  .media
  .media-content
  .swiper-section
  .media-swiper .swiper-pagination{
    display: none;
  }

.main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .cards
  .card
  .media-card-img
  .card-img-top:hover {
  transform: scale(1.1);
}

.main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .cards
  .card
  h5 {
  font-size: 16px;
  font-weight: 600;
  color: #2a2a2a;
}

.main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .cards
  .card
  p {
  font-size: 13px;
  font-weight: 500;
  color: #2a2a2a;
}

.main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content {
  position: relative;
  margin-top: 14%;
}
.main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .cards {
  position: absolute;
  top: 0;
}

.main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .cards .card .card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}

.main
  .media
  .media-content
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .bg-img
  .card-bg-img {
  width: 445px;
  height: 100%;
}

.main
  .media
  .media-content
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .bg-img {
  position: relative;
  bottom: 70px;
}

.main .media .media-content .media-swiper .swiper-button-next,
.main .media .media-content .media-swiper .swiper-button-prev {
  position: absolute;
  bottom: 60px;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.main .media .media-content .media-swiper .swiper-button-next,
.main .media .media-content .media-swiper .swiper-rtl .swiper-button-prev {
  right: 0%;
  left: auto;
  top: 5%;
}

.main .media .media-content .media-swiper .swiper-button-prev, .main .media .media-content .media-swiper .swiper-rtl .swiper-button-next {
  right: 6%;
  left: auto;
  top: 5%;
}

.main .media .media-content .media-swiper .swiper-button-next:after, .main .media .media-content .media-swiper .swiper-button-prev:after {
  font-size: 9px;
  color: #ffffff;
  background: #000000;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .cards
  .card
  .card-body
  .read-more
  .read-more-btn {
  width: 36px;
  height: 36px;
}

.main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .cards
  .card
  .card-body
  button {
  background: none;
  border: none;
} */

/* swiper css end */
@media (min-width: 768px) and (max-width: 991px) {
  .main .media .media-content .media-swiper .swiper-button-prev, .main .media .media-content .media-swiper .swiper-rtl .swiper-button-next {
    right: 7%;
}
.main .media .media-content .media-swiper .swiper-button-next, .main .media .media-content .media-swiper .swiper-rtl .swiper-button-prev {
  right: 3%;
}

.main .media .media-content .headeing .swiper-heading {
  text-align: center;
  margin-top: -5%;
}
}

@media (min-width: 992px) and (max-width: 1023px) {

  .main .media .media-content .media-swiper .swiper-button-prev, .main .media .media-content .media-swiper .swiper-rtl .swiper-button-next {
    right: 7%;
}
  .main .media .media-content .media-swiper .swiper-button-next, .main .media .media-content .media-swiper .swiper-rtl .swiper-button-prev {
    right: 0%;
  }
  .main .media .media-content .swiper-section {
    margin-bottom: 5%;
  }

  .main .media .media-content .headeing .swiper-heading h1 {
    font-size: 25px;
  }

  .main .media .media-content .headeing .swiper-heading h5 {
    font-size: 15px;
  }

  .main .media .media-content .swiper-section {
    margin-bottom: 5%;
  }

  .main .media .media-content .headeing .swiper-heading h1 {
    font-size: 25px;
  }

  .main .media .media-content .headeing .swiper-heading h5 {
    font-size: 20px;
}

  .main .media .media-content .headeing .main-img .swiper-main-img {
    width: 100%;
    height: auto;
}
.main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .cards .card .card-body {
  padding: 0px;
}


.main
  .media
  .media-content
  .headeing
  .main-img
  .overlay
  .main-text-holder
  .text-holder
  h1 {
    font-size: 20px;
}

.main
  .media
  .media-content
  .headeing
  .main-img
  .overlay
  .main-text-holder
  .text-holder
  p {
    margin-bottom: 5px;
}

.main
  .media
  .media-content
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .bg-img
  .card-bg-img {
    width: 100%;
    height: auto;
}

.main .media .media-content .swiper-section .media-swiper .slider-content .img-content .media-card-img-content .cards {
  top: 55px;
}

.main .media .media-content .swiper-section .media-swiper .slider-content .img-content .media-card-img-content {
  margin-top: 0%;
}

.main .media .media-content .media-swiper .slider-content .img-content .media-card-img-content .bg-img {
  bottom: 0%;
}

.main .media .media-content .swiper-section .media-swiper .slider-content .img-content .media-card-img-content .cards {
  top: 15%;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {

  .main .media .media-content .media-swiper .swiper-button-prev, .main .media .media-content .media-swiper .swiper-rtl .swiper-button-next {
    right: 7%;
}
  .main .media .media-content .swiper-section {
    margin-bottom: 5%;
  }

  .main .media .media-content .headeing .swiper-heading h1 {
    font-size: 25px;
  }

  .main .media .media-content .headeing .swiper-heading h5 {
    font-size: 20px;
}

  .main .media .media-content .headeing .main-img .swiper-main-img {
    width: 100%;
    height: auto;
}
.main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .cards .card .card-body {
  padding: 0px;
}


.main
  .media
  .media-content
  .headeing
  .main-img
  .overlay
  .main-text-holder
  .text-holder
  h1 {
    font-size: 20px;
}

.main
  .media
  .media-content
  .headeing
  .main-img
  .overlay
  .main-text-holder
  .text-holder
  p {
    margin-bottom: 5px;
}

.main
  .media
  .media-content
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .bg-img
  .card-bg-img {
    width: 100%;
    height: auto;
}

.main .media .media-content .swiper-section .media-swiper .slider-content .img-content .media-card-img-content .cards {
  top: 55px;
}

.main .media .media-content .swiper-section .media-swiper .slider-content .img-content .media-card-img-content {
  margin-top: 0%;
}

.main .media .media-content .media-swiper .slider-content .img-content .media-card-img-content .bg-img {
  bottom: 0%;
}

.main .media .media-content .swiper-section .media-swiper .slider-content .img-content .media-card-img-content .cards {
  top: 15%;
}
}

@media (min-width: 1200px) and (max-width: 1399px) {
 
  .main .media .media-content .swiper-section {
    margin-bottom: 5%;
  }

  .main .media .media-content .headeing .swiper-heading h1 {
    font-size: 25px;
  }

  .main .media .media-content .headeing .swiper-heading h5 {
    font-size: 15px;
  }

  .main .media .media-content .swiper-section {
    margin-bottom: 0%;
  }

  .main .media .media-content .headeing .swiper-heading h1 {
    font-size: 25px;
  }

  .main .media .media-content .headeing .swiper-heading h5 {
    font-size: 20px;
}

  .main .media .media-content .headeing .main-img .swiper-main-img {
    width: 100%;
    height: auto;
}


.main
  .media
  .media-content
  .swiper-section
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .cards .card .card-body {
  padding: 0px;
}


.main
  .media
  .media-content
  .headeing
  .main-img
  .overlay
  .main-text-holder
  .text-holder
  h1 {
    font-size: 20px;
}

.main
  .media
  .media-content
  .headeing
  .main-img
  .overlay
  .main-text-holder
  .text-holder
  p {
    margin-bottom: 5px;
}

.main
  .media
  .media-content
  .media-swiper
  .slider-content
  .img-content
  .media-card-img-content
  .bg-img
  .card-bg-img {
    width: 100%;
    height: auto;
}

.main .media .media-content .swiper-section .media-swiper .slider-content .img-content .media-card-img-content .cards {
  top: 55px;
}

.main .media .media-content .swiper-section .media-swiper .slider-content .img-content .media-card-img-content {
  margin-top: 0%;
}

.main .media .media-content .media-swiper .slider-content .img-content .media-card-img-content .bg-img {
  bottom: 0%;
}

.main .media .media-content .swiper-section .media-swiper .slider-content .img-content .media-card-img-content .cards {
  top: 12%;
}
}
