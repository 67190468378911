
.board-members-section .sliders-div .swiper{
    padding: 15px;
}

.board-members-section .sliders-div .broadband-swiper .swiper-pagination{
  display: none;
}

.board-members-section .sliders-div .swiper-button-next:after, .board-members-section .sliders-div   .swiper-button-prev:after {
  font-size: 9px;
  color: #ffffff;
  /* width: 30px; */
  background: linear-gradient(to top, #B615CF 0%, #4D0D54 100%);
  /* height: 27px; */
  width: 24px;
  height: 24px;
  text-align: center;
  padding: 7px;
  border-radius: 50px;
  display: block;
}

  .board-members-section .sliders-div  .swiper-button-next, .board-members-section .sliders-div  .swiper-rtl .swiper-button-prev {
    right: 48%;
    left: auto;
    top: 96%;
  }

 
  
  .board-members-section .sliders-div  .swiper-button-prev,   .board-members-section .sliders-div  .swiper-rtl .swiper-button-next {
    left: 48%;
    right: auto;
    top: 96%;
    margin-left: -6px;
  }


  .board-members-section .sliders-div .swiper-wrapper{
    padding-bottom: 30px !important;
  }

  @media (min-width:768px) and (max-width:992px){
    .board-members-section .sliders-div  .swiper-button-next, .board-members-section .sliders-div  .swiper-rtl .swiper-button-prev {
      right: 46%;
  }
  .board-members-section .sliders-div  .swiper-button-prev, .board-members-section .sliders-div  .swiper-rtl .swiper-button-next {
    left: 47%;
  } 
  .board-members-section{
    margin-bottom: 100px;
  }
}

  @media (min-width:993px) and (max-width:1200px){
    .board-members-section .sliders-div  .swiper-button-next, .board-members-section .sliders-div  .swiper-rtl .swiper-button-prev {
      right: 47%;
  }
  .board-members-section{
    margin-bottom: 100px;
  }
  }


  @media (min-width:1201px) and (max-width:1400px){
    .board-members-section .sliders-div  .swiper-button-next, .board-members-section .sliders-div  .swiper-rtl .swiper-button-prev {
      left: 50%;
  }
  }