.Performance {
  margin-top: 5%;
}

.Performance .performance-heading h1 {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: #000000;
}

.Performance .main-content-holder {
    margin-top: 5%;
}

.Performance .main-content-holder .file-box .file .file-img {
  width: 15px;
}

.Performance .main-content-holder .file-box .date-holder {
    background: #E0051D;
    border-radius: 5px;
    width: fit-content;
    margin: 5% auto;
    padding: 0% 5%;
}



.Performance .main-content-holder .file-box .date-holder p {
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    padding: 2px;
}

.Performance .main-content-holder .file-box .text-holder p{
    text-align: center;
font-size: 14px;
font-weight: 500;
color: #000000;
}

.Performance .main-content-holder  .file-box {
    background: #F5F8FF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    height: 264px;
    margin-bottom: 10%;
    padding-top: 10%;
    position: relative;
}

.Performance .main-content-holder .file-box .file {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0% auto;
    border-radius: 50px;
}
.Performance .main-content-holder .file-box .file:hover{
    background-color: #562278 ;
}

.Performance .main-content-holder .file-box .file:hover .file-img {
    filter: invert(1);
}

.Performance .main-content-holder .file-box .purple-read-more {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
}

.Performance .main-content-holder .file-box .purple-read-more  #btn-size {
    padding: 5px 20px;
    box-shadow: none;
}


@media(min-width:768px) and (max-width:991px){
.Performance .performance-heading h1 {
    font-size: 25px;
}
}

@media(min-width:1024px) and (max-width:1199px){
.Performance .main-content-holder .file-box .purple-read-more  #btn-size {
    padding: 4px 20px;
}

.Performance .main-content-holder .file-box .purple-read-more {
    bottom: 20px;
}

.Performance .main-content-holder  .file-box {
    height: 250px;
}
}