.main .Culturelottie-icon {
    width: 100px;
    height: 100px;
    cursor: pointer;
    background: #FFFFFF ;
    box-shadow: 0px 0px 16px #0000001C;
    border-radius: 50px;
}

@media (min-width: 768px) and (max-width: 992px) {

    .main .Culturelottie-icon {
        width: 50px;
        height: 50px;
    }
    }

    @media (min-width: 992px) and (max-width: 1023px) {
        .main .Culturelottie-icon {
            width: 80px;
            height: 80px;
        }
      }
      
      @media (min-width: 1024px) and (max-width: 1199px) {
        .main .Culturelottie-icon {
            width: 80px;
            height: 80px;
        }
      }

      @media (min-width: 1200px) and (max-width: 1399px) {
        .main .Culturelottie-icon {
            width: 80px;
            height: 80px;
        }
      }