.life-at-section {
    background-image: url('/public/assets/image/our-people/life_at/banner.png');
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0px;
    margin: 50px 0px 200px 0px;
}
.life-at-section .section-1 .qcil-title{
    font-family: Poppins;
    font-weight: 600;
    font-size: 34px;
    color: #000;
    margin-top: 25px;
    margin-bottom: 20px;
}
.life-at-section .section-1 .sub-content{
    font-weight: 500;
    font-size: 15px;
    color: #663885;
}

.qcil-members-card .qcil-swiper .img-div {
    box-shadow: 0px 0px 23px #00000012;
    border-radius: 8px;
}


.life-at-section .section-2 {
    text-align: center;
}
.life-at-section .section-2 .group-img{
    width: 80%;
    height: auto;
}



.qcil-members-section{
    position: relative;
    top: 100px;
}
.qcil-members-section .qcil-members-card{
    width: 100%;
    height: 310px;
    box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.07);
    position: relative;
    width:100%;
    height: 100%;
    transition: 0.4s;
}
.qcil-members-section .qcil-members-card .img-div .qcl-member-img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}


.qcil-members-section .qcil-members-card .overflow-color{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 0%;
    background: linear-gradient(0deg, white, transparent);
    transition: 0.4s;
}

.qcil-members-section .qcil-members-card:hover .overflow-color{
    height: 56%;
}


.qcil-members-section .qcil-members-card .member-details{
display: none;
}

.qcil-members-section .qcil-members-card:hover .member-details{
    display: block;    
    position: absolute;
    width: 100%;
    bottom: 15px;
}
.qcil-members-section .qcil-members-card:hover .member-details .member-name{
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    font-size: 18px;
    color: #2a2a2a;
    margin-bottom: 0px;
}

.qcil-members-section .qcil-members-card:hover .member-details .member-position{
    font-weight: normal;
    font-size: 14px;
    color: #2a2a2a;
    margin-bottom: 0px;
    text-align: center;
}

.qcil-members-section .qcil-swiper {
    padding-bottom: 3%;
}


.qcil-members-section .qcil-swiper
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #562278;
  width: 10px;
  height: 10px;
}


.qcil-members-section .qcil-swiper
  .swiper-pagination-bullet{
  background: #00A7E5;
  opacity:  1;
}


.qcil-members-section .qcil-swiper .swiper-button-prev {
    display: none;
}
.qcil-members-section  .qcil-swiper .swiper-button-next {
    display: none;
}
.qcil-members-section .swiper-pagination {
    display: none;
}


@media (min-width:768px) and (max-width:1200px){
    .life-at-section .section-1 .qcil-title {
        font-size: 30px;
    }

    .life-at-section .section-1 .sub-content {
        font-size: 13px;
    }
}