/* page up css start */

.main-page-up {
    position: relative;
    margin-top: 5%;
}


/* .page-up {
    position: absolute;
    width: 100%;
    text-align: center;
    display: flex;
    top: 90%;
    justify-content: center;
    z-index: 1;
  } */

  .page-up {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0px;
    justify-content: center;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.page-up .page-up-circle .text p{
  font-size: 15px;
  font-weight: 500;
}

.page-up .page-up-circle .text .up-arrow {
  font-size: 1px;
}
  
 .page-up .page-up-circle .text .up-arrow .arrow {
    animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
    cursor: pointer;
    height: 20px;
  }
  
 .page-up .page-up-circle .text .up-arrow .arrow:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 100%;
    border-radius: 5px;
    background: black;
    transform: rotate(-90deg);
    transform-origin: 0% 100%;
  }
  @keyframes bounce {
    50% {
      transform: translateY(-15px);
    }
  }
  
  /* .page-up .page-up-circle {
    width: 102px;
    height: 102px;
    background: linear-gradient(to top, #B615CF 0%, #4D0D54 100%);
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: end;
    border-radius: 50px;
    cursor: pointer;
  } */

  .page-up .page-up-circle {
    width: 80px;
    height: 80px;
    background: linear-gradient(to top, #810ae3db 0%, #4d0d54fa 100%);
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: end;
    border-radius: 50px;
    cursor: pointer;
    position: absolute;
}
  
  /* page up css end */
  @media (min-width:768px) and (max-width:991px){
    .page-up .page-up-circle {
      width: 70px;
      height: 70px;
  }
  
  
  .page-up .page-up-circle .text p {
    font-size: 12px;
    font-weight: 500;
  }
  
  }



  @media (min-width:992px) and (max-width:1023px){
    .page-up .page-up-circle {
      width: 70px;
      height: 70px;
  }
  
  
  .page-up .page-up-circle .text p {
    font-size: 12px;
    font-weight: 500;
  }
  
  }

@media (min-width:1024px) and (max-width:1399px){
  .page-up .page-up-circle {
    width: 70px;
    height: 70px;
}


.page-up .page-up-circle .text p {
  font-size: 12px;
  font-weight: 500;
}


}