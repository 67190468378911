
.RSPBprogressBar {
  top: 60px;
  z-index: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  background-color: #9926e538;
}

#bottom-space-2 {
  margin-bottom: 3%;
  padding-top: 12%;
  padding-bottom: 5%;
}
.userform {
  display: flex;
}
.userForm .form {
  display: flex;
  flex-direction: column;
  margin: auto;
}
.userForm .userForm-container {
  width: 50%;
  margin: auto;
  max-width: 400px;
}
.userForm label {
  text-align: left;
}

.userForm .userForm-container-footer {
  display: flex;
  margin: auto;
  width: 100%;
}
.userForm span {
  font-weight: lighter;
  color: grey;
}
.userForm .card-container {
  display: flex;
  justify-content: space-between;
}

.userForm .card-input-element {
  display: none;
}

.userForm .card-input {
  margin: 10px;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 5%;
  justify-content: space-between;
  margin: 5px;
}

.userForm .card-input:hover {
  cursor: pointer;
}

.userForm .card-input-element:checked + .card-input {
  box-shadow: 0 0 1px 1px #664de5;
}
.userForm .panel-heading {
  font-size: 15px;
}

.userForm .userForm.panel-body {
  font-size: 12px;
  font-weight: lighter;
}

.userForm .inner-step-nav-btn{
  display: flex;
  justify-self: center;
  align-items: center;
}

.inner-step-nav-btn .back-btn {
  position: absolute;
  left: 38%;
  bottom: 75px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(86, 34, 120);
  font-weight: 500;
  font-size: 15px;
  padding: 3px 40px;
  text-align: left;
  color: rgb(6, 6, 6);
  border-radius: 50px;
}

.inner-step-nav-btn .back-btn:hover {
  background-color: #562278;
  color: #f8f9fa;
}


.inner-step-nav-btn .next-btn {
  position: absolute;
  left: 51%;
  bottom: 75px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(86, 34, 120);
  font-weight: 500;
  text-align: left;
  color: rgb(6, 6, 6);
  font-size: 15px;
  padding: 3px 40px;
  border-radius: 50px;
}

.inner-step-nav-btn .next-btn:hover {
  background-color: #562278;
  color: #f8f9fa;
}

.ProgressBar .step {
  align-items: center;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 50%;
  color: #562278;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  height: 44px;
  font-family: 'Poppins';
  justify-content: center;
  width: 44px;
}
.ProgressBar .step.completed {
  background-color: #664de5;
}

@media (min-width: 768px) and (max-width: 991px) {
  .RSPBprogressBar {
    top: 35px;
}
.inner-step-nav-btn .next-btn {
  bottom: 50px;
  font-size: 12px;
  padding: 5px 35px;
}
.inner-step-nav-btn .back-btn {
  left: 36%;
  bottom: 50px;
  font-size: 12px;
  padding: 5px 35px;
}
#bottom-space-2 {
  padding-top: 12%;
}
}

@media (min-width: 992px) and (max-width: 1023px) {
  #bottom-space-2 {
    padding-top: 15%;
    padding-bottom: 10%;
}
  .inner-step-nav-btn .next-btn {
    bottom: 50px;
    font-size: 12px;
    padding: 5px 35px;
  }
.inner-step-nav-btn .back-btn {
  left: 36%;
  bottom: 50px;
  font-size: 12px;
  padding: 5px 35px;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {


  #bottom-space-2 {
    padding-top: 12%;
    padding-bottom: 10%;
}

.inner-step-nav-btn .next-btn {
  font-size: 12px;
  padding: 5px 35px;
}

.inner-step-nav-btn .back-btn {
  left: 36%;
  bottom: 75px;
  font-size: 12px;
  padding: 5px 35px;
}
}

@media (min-width: 1200px) and (max-width: 1399px) {
  #bottom-space-2 {
    padding-top: 15%;
}
  .inner-step-nav-btn .back-btn {
    left: 35%;
}

#nav-back-btn {
  left: 35%;
}
}





