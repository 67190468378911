.founder-heading {
    margin-top: 5%;
}

.Founder .founder-heading h1{
    font-size: 28px;
    font-weight: 600;
    color: #2A2A2A;
    text-align: center;
}


.Founder  .founder-card {
    margin-top: 5%;
}
@media (min-width:768px) and (max-width:991px){
    .Founder  .founder-card {
        margin-bottom: 10%;
    }
}

@media (min-width:992px) and (max-width:1023px){
    .Founder  .founder-card {
        margin-bottom: 10%;
    }
}
