.main .Mission {
  background: #f3f8ff;
  margin-top: 5%;
  padding-bottom: 2%;
}

.main .Mission .Mission-content .text-holder {
  text-align: center;
  padding-top: 5%;
  padding-right: 3%;
}

.main .Mission .Mission-content .text-holder h4 {
  font-size: 30px;
  font-weight: 700;

  color: #010101;
}

.main .Mission .Mission-content .Mission-swiper  {
  text-align: center;
  margin-top: 5%;
  padding-bottom: 3%;
}

/* .main .Mission .Mission-content .mission-slider .swiper-pagination{
  display: none;
} */

.main .Mission .Mission-content .Mission-swiper  .swiper-button-next:after,
.main .Mission .Mission-content .Mission-swiper  .swiper-button-prev:after {
  display: none;
}

.main .Mission .Mission-content .Mission-swiper
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: purple;
  width: 10px;
  height: 10px;
}


.main .Mission .Mission-content .Mission-swiper
  .swiper-pagination-bullet{
  background: #00A7E5;
  opacity:  1;
}


.main .Mission .Mission-content .Mission-swiper.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  display: flex;
  justify-content: center;
  align-items: center;
}


.main .Mission .Mission-content .Mission-swiper {
  padding-bottom: 60px;
}

.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .mission-slider-img {
    width: 100%;
    height: 215px;
    position: relative;
    margin-top: 15px;
}

.main .Mission .Mission-content .mission-slider .Mission-swiper .img-holder {
  position: relative;
  margin: 0% 5%;
}

.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .overlay {
  background-image: linear-gradient(180deg, rgb(0 0 0 / 0%) 10%, #000000 100%);
  height: 70%;
  width: 100%;
  z-index: 1;
  position: absolute;
  bottom: 0;
  border-radius: 5px;
}

.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder:hover .overlay{
    height: 90%;
    transition: 0.2s ease-in;
  }

.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .overlay
  .text-holder {
    padding-left: 3%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    height: 100%;
    padding-bottom: 5%;
}

.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .overlay
  .text-holder
  .text
  h5 {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  transition: 0.5s;
}

.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .overlay
  .text-holder:hover
  .text
  h5 {
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    transform: translate(0, -10px);
}

.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .overlay
  .text-holder
  .text
  p {
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  color: #ffffff;
  display: none;
}

.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder:hover  .overlay
  .text-holder
  .text
  p {
  display: block;
  transition: 0.5s ease-out;
  transform: translate(0, 3px);
}
.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder:hover .overlay{
    height: 90%;
    transition: 0.5s ease-in;
  }

  .Mission-swiper.swiper-slide.swiper-slide-active {
    transform: scale(1.1) translateZ(0) !important;
}

.Mission-swiper.swiper-backface-hidden .swiper-slide {
  transform: scale(.84) translateZ(0) !important;
}
.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper.swiper-backface-hidden .swiper-slide {
  transform: scale(.84) translateZ(0) !important;
}
        
/* .main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper.swiper-slide.swiper-slide-active {  
  transform: scale(1) !important;
  opacity: 1 !important;

} */

.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper.swiper-backface-hidden .swiper-slide.swiper-slide-active {
  transform: scale(1.1) translateZ(0) !important;
}

/* .main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .overlay */
  /* .btn-holder{
  padding-bottom: 2%;
} */

/* .main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .overlay */
  /* .btn-holder
  .read-more {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 50px;
    border: none;
    padding: 0;
    font-size: 15px;
    color: #000000;
} */

/* .main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .overlay */
  /* .btn-holder
  .read-more:hover {
    background-color: #9926e5;
} */

/* .main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .overlay */
  /* .btn-holder
  .read-more:hover .read-more-btn {
    margin-left: 9px;
    color: #f3f8ff;
    transition: 0.3s ease-in;
} */

@media (min-width:1024px) and (max-width:1199px){
  .main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .mission-slider-img {
    width: 100%;
    height: auto;
}
}

@media (min-width:992px) and (max-width:1023px){
  .main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .mission-slider-img {
    width: 100%;
    height: auto;
}
}

@media (min-width:768px) and (max-width:991px){
  .main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .mission-slider-img {
    width: 100%;
    height: auto;
}

.main .Mission .Mission-content .text-holder h4 {
  font-size: 25px;
}

.main .media .media-content .swiper-section .navigation {
  right: 5%;
}

.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .overlay
  .text-holder
  .text
  h5 {
    font-size: 15px;
}
.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .overlay
  .text-holder
  .text
  p {
    font-size: 10px;
    margin-bottom: 3px;
}

.main
  .Mission
  .Mission-content
  .mission-slider
  .Mission-swiper
  .img-holder
  .overlay
  .text-holder:hover
  .text
  h5 {
    font-size: 17px;
    transform: translate(0, 4px);
}

}

@media (min-width:992px) and (max-width:1023px){
.main .Mission .Mission-content .text-holder h4 {
  font-size: 25px;
}


}