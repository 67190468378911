.key-value-section{
    margin-top: 5%;
    margin-bottom: 10%;
}

.key-value-section .bg-img-div{
    position: relative;
}
.key-value-section .bg-img-div .bg-img{
    width: 100%;
    height: 800px;
    object-fit: cover;
}
.key-value-section .bg-img-div .overlay-color{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1b002dd9;
    top: 0px;
}

.key-value-section .bg-img-div .overlay-content{
    position: absolute;
    top: 0px;
    width: 100%;
}
.key-value-section .bg-img-div .overlay-content .values-main-div {
    position: relative;
    width: 100%;
    right: 2%;
}

.key-value-section .bg-img-div .overlay-content .values-main-div .center-main-circle {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    background-color: #6e5d782b;
    position: absolute;
    left: 40%;
    right: 40%;
    top: 80px;
    bottom: 0;
    border: 45px solid #5d406e;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: 2px solid #fff;
    outline-offset: -22px;
    transition: 0.2s;
}


.key-value-section .bg-img-div .overlay-content .values-main-div .center-main-circle 
.circle-name {
    font-family: Poppins;
    font-weight: bold;
    font-size: 30px;
    color: #fff;
    width: 100%;
    height: 100%;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 0px;
    background: transparent;
}

.key-value-section .bg-img-div .overlay-content .values-main-div .center-main-circle:hover 
.circle-name {
    color: #562278;
    transition: 0.2s;
    background: #ffffff;
}

.key-value-section .bg-img-div .overlay-content .values-main-div .sub-circle {
    width: 117px;
    height: 117px;
    border-radius: 100%;
    background-color: #ffffff33;
    border: 20px solid #5622784d;
    outline: 1px solid #000;
    outline-offset: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.key-value-section .bg-img-div .overlay-content .values-main-div .sub-circle:hover  {
    background-color: #fff;
    border: 20px solid #5d406e;
}




.key-value-section .bg-img-div .overlay-content .values-main-div .sub-circle .inner-icon{
    height: 44px;
}
.key-value-section .bg-img-div .overlay-content .values-main-div  .key-name{
    font-family: Poppins;
    font-weight: normal;
    font-size: 22px;
    color: #fff;
    margin-bottom: 0px;
}


.key-value-section .bg-img-div .overlay-content .values-main-div .first-circle-pos {
    position: absolute;
    top: 110px;
    left: 18%;
}

.key-value-section .bg-img-div .overlay-content .values-main-div .second-circle-pos {
    position: absolute;
    top: 260px;
    left: 17%;
}

.key-value-section .bg-img-div .overlay-content .values-main-div .third-circle-pos {
    position: absolute;
    top: 400px;
    left: 21%;
}
.key-value-section .bg-img-div .overlay-content .values-main-div .fourth-circle-pos{
    position: absolute;
    top: 440px;
    left: 48%;
}

.key-value-section .bg-img-div .overlay-content .values-main-div .fifth-circle-pos {
    position: absolute;
    top: 400px;
    right: 21%;
}

.key-value-section .bg-img-div .overlay-content .values-main-div .sixth-circle-pos{
    position: absolute;
    top: 260px;
    right: 9%;
}
.key-value-section .bg-img-div .overlay-content .values-main-div .seventh-circle-pos{
    position: absolute;
    top: 110px;
    right: 12%;
}

.key-value-section .bg-img-div .overlay-content .next-img-div .next{
    width: 22px;
    position: relative;
    left: -10px;
}

.key-value-section .bg-img-div .overlay-content .next-img-div .next2{
    width: 22px;
    position: relative;
    left: 10px;
}

/* .key-value-section .bg-img-div .overlay-content .values-main-div .d-flex {
    margin: 0px -30px !important;
} */

.keyvalues-modal-sec .modal-body{
    text-align: center ;
}
.keyvalues-modal-sec .modal-body .mod-tt{
    font-family: Poppins;
    font-weight: 600;
    font-size: 24px;
    color: #2a2a2a;
}
.keyvalues-modal-sec .modal-content {
    margin: auto;
    width: 80%;
}
.keyvalues-modal-sec .modal-header {
    border-bottom: 0px;
}

.keyvalues-modal-sec .mod-sb-tt{
    font-family: Poppins;
    font-weight: normal;
    font-size: 22px;
    color: #000;
    margin-top: 20px;
}
.keyvalues-modal-sec .circle-div{
    width: 104px;
    height: 104px;
    border-radius: 100%;
    border: 1px solid #000;
    margin: auto;   
    display: flex;
    justify-content: center;
    align-items: center; 
    outline: 1px solid #ccc;
    outline-offset: -10px;
}
.keyvalues-modal-sec .circle-div .circle-inner{
    width: 50px;
}

 .modal-backdrop {
    --bs-backdrop-bg: transparent !important;
}

@media (min-width:768px) and (max-width:992px){
   
    .key-value-section .bg-img-div .bg-img {
        height: 500px;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .center-main-circle {
        width: 200px;
        height: 200px;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .center-main-circle 
    .circle-name {
        font-size: 20px;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .sub-circle {
        width: 70px;
        height: 70px;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .sub-circle .inner-icon {
        height: 22px;
    }   
    .key-value-section .bg-img-div .overlay-content .values-main-div  .key-name {      
        font-size: 15px;        
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .first-circle-pos {
       
        left: 14%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .second-circle-pos {
      
        top: 200px;
        left: 12%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .third-circle-pos {
      
        top: 280px;
        left: 16%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .fourth-circle-pos {
       
        top: 307px;
        left: 50%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .seventh-circle-pos {      
        top: 110px;
        right: 2%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .sixth-circle-pos {
       
        top: 200px;
        right: -2%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .fifth-circle-pos {
     
        top: 280px;
        right: 13%;
    }

    .key-value-section .bg-img-div .overlay-content .values-main-div {
        right: 7%;
    }
}

@media (min-width:993px) and (max-width:1200px){
    .key-value-section .bg-img-div .overlay-content .values-main-div {
        right: 6%;
    }
    .key-value-section .bg-img-div .bg-img {
        height: 600px;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .center-main-circle {
        width: 250px;
        height: 250px;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .center-main-circle 
    .circle-name {
            font-size: 25px;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .sub-circle {
        width: 90px;
        height: 90px;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div  .key-name {        
        font-size: 18px;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .second-circle-pos {
       
        top: 225px;
        left: 15%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .third-circle-pos {
      
        top: 330px;
        left: 18%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .fourth-circle-pos {
       
        top: 370px;
        left: 48%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .seventh-circle-pos {
        top: 110px;
        right: 8%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .sixth-circle-pos {
       
        top: 230px;
        right: 3%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .fifth-circle-pos {
       
        top: 340px;
        right: 18%;
    }
}

@media (min-width:1201px) and (max-width:1400px){
    .key-value-section .bg-img-div .overlay-content .values-main-div {
        right: 7%;
    }
    .key-value-section .bg-img-div .bg-img {
        height: 690px;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .first-circle-pos {
        left: 15%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .second-circle-pos {
        left: 13%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .third-circle-pos {
        left: 17%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .seventh-circle-pos {       
        right: 4%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .sixth-circle-pos {
        right: 0%;
    }
    .key-value-section .bg-img-div .overlay-content .values-main-div .fifth-circle-pos {
        
        right: 16%;
    }
}