.main .sms-icon {
    width: 100px;
    height: 100px;
    cursor: pointer;
}

@media (min-width:768px) and (max-width:992px){
  .main .sms-icon {
    width: 67px;
    height: 67px;
  }
  }