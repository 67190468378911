.Accreditations {
    margin-top: 4%;
    position: relative;
}

.Accreditations .Accreditations-heading h1{
    font-size: 34px;
    font-weight: 600;
    color: #2A2A2A;
    text-align: center;
}

.Accreditations .accredition-main-containt-holder .main-img-text-holder-div .img-holder .accreditations-img {
    width: 100%;
    height: 100%;
}

.Accreditations .accredition-main-containt-holder .main-img-text-holder-div .img-holder {
    margin: 5% 0%;
}

.Accreditations .accredition-main-containt-holder .blue-bg {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E6F8FF 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 60%;
}

.Accreditations .accredition-main-containt-holder  .content-div {
    margin: 2% 0%;
}

@media (min-width:768px) and (max-width:991px){
.Accreditations .Accreditations-heading h1 {
    font-size: 25px;
}

.Accreditations {
    margin-bottom: 10%;
}
}


@media (min-width:992px) and (max-width:1023px){
    .Accreditations .Accreditations-heading h1 {
        font-size: 25px;
    }
    
    .Accreditations {
        margin-bottom: 10%;
    }
    }
    @media (min-width:1024px) and (max-width:1399px){
    .Accreditations .Accreditations-heading h1 {
        font-size: 30px;
    }
}

    @media (min-width:1400px) and (max-width:1440px){
    .Accreditations .Accreditations-heading h1 {
        font-size: 30px;
    }
}