#footer-body {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-bottom: 1%;
}

#footer-body:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#e3f1ff, #ffffff);
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  transform: scaleX(2);
}

.footer {
  color: #260645;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  max-width: 100%;
  padding-top: 10%;
}

.footer .footer-content .list ul {
  padding-left: 1rem;
}

.footer .footer-content .list {
  margin-bottom: 20%;
}

.footer .footer-content .list .footer-title h4 {
  font-size: 18px;
  color: #000000;
  font-weight: 600;
}

.footer .footer-content .list .footer-title h4:after {
  content: "";
  display: block;
  width: 10%;
  border: 1px solid #000000;
  margin-top: 2%;
}
.footer .footer-content .list p {
  font-size: 14px;
  color: #000000;
  margin-bottom: 5px;
  font-weight: 500;
}

.footer .footer-content .list p:hover {
  color: #562278;
}

.footer .footer-content .list .list-dots {
  list-style: none;
}

.footer .footer-content .list .list-padding {
  padding-left: 0rem;
}

.footer .footer-content .list .contact {
  display: flex;
  margin-bottom: 5%;
}
.footer .footer-content .list .contact .icon {
  margin-right: 5%;
}
.footer .footer-content .list .contact .icon {
  background: #e3e5eb;
  /* height: 20px; */
  /* width: 20px; */
  width: 26px;
  height: 26px;
  text-align: center;
  border-radius: 50px;
  border: 1px solid #e0e2e7;
}

.footer .footer-content .list .contact .icon svg {
  color: #562278;
  font-size: 12px;
  margin-bottom: 0px;
}

.footer .copyright {
  border-top: 1px solid #000000;
  position: relative;
}

.footer .copyright .privacy {
  padding-top: 2%;
  display: flex;
}

.footer .copyright .privacy .privacy-Disclaimer {
  margin-right: 3%;
}

.footer .copyright .privacy .privacy-Disclaimer p {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.footer .copyright .copyright-link .social-icon {
  display: flex;
  justify-content: end;
  position: absolute;
  right: 0;
  top: 20px;
}
.footer .copyright .copyright-link .link p {
  font-size: 13px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0;
}

.footer .copyright .copyright-link .social-icon .icon {
  margin-right: 10%;
}

.footer .copyright .copyright-link .social-icon .icon .social {
  width: 24px;
  height: 24px;
}


/* responsive css start */

@media (min-width:768px) and (max-width:992px){
  #footer-body:before {
    height: 50%;
}

.footer .footer-content .list .footer-title h4 {
  font-size: 15px;
}

.footer .footer-content .list p {
  font-size: 12px;
}

.footer .copyright .privacy .privacy-Disclaimer p {
  font-size: 12px;
}

.footer .copyright .copyright-link .link p {
  font-size: 12px;
}

.footer .copyright .copyright-link .social-icon .icon .social {
  width: 20px;
  height: 20px;
}
}

@media (min-width:992px) and (max-width:1023px){
.footer .footer-content .list .footer-title h4 {
  font-size: 15px;
}
}

@media (min-width:1024px) and (max-width:1199px){
  .footer .footer-content .list .footer-title h4 {
    font-size: 15px;
  }
  }

/* responsive css end */