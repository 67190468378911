.find-content .text-holder h1{
    font-size: 24px;
    font-weight: 600;
    color: #000000;
}

.find-content .text-holder p{
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.find-content {
    margin-top: 5%;
    padding-bottom: 2%;
}

.find-us-map .map-style{
    width: 100%;
    height: 668px;
    overflow: hidden;
    border-radius: 30px;
   }

@media (min-width: 768px) and (max-width:991px) {
  
    .find-us-map > div.map-style{
     height: 350px;
     border-radius: 10px !important;
    }

  }

  @media (min-width: 992px) and (max-width:1023px) {
  
    .find-us-map > div.map-style{
     height: 500px;
     border-radius: 15px !important;
    }

  }

  @media (min-width: 1024px) and (max-width:1199px) {
  
    .find-us-map > div.map-style{
     height: 450px;
     border-radius: 20px !important;
    }
  }

  @media (min-width: 1200px) and (max-width:1399px) {
  
    .find-us-map > div.map-style{
     height: 500px;
     border-radius: 20px !important;
    }
  }

  @media (min-width: 1400px) and (max-width:1440px) {
  
    .find-us-map > div.map-style{
     height: 500px;
     border-radius: 20px !important;
    }
  }