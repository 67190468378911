.leadership-team-section .leadership-bg{
    background-color: #EFFBFF;
    padding:1px 30px 30px 30px;
    border-radius: 47px;
    margin:50px 0px;
}

@media (min-width:768px) and (max-width:992px){
    .members-card-div .member-name {
        font-size: 13px;
    }
    .members-card-div .member-position {
        font-size: 12px;
    }
    .members-card-div {
        width: 100%;
        height: 257px;
    }

    .members-card-div .profile-img-div {
        width: 100px;
        height: 100px;
    }
}


@media (min-width:993px) and (max-width:1200px){

}

@media (min-width:1201px) and (max-width:1400px){
    
}