.join-us-section{
    padding: 70px 0px;
}
.join-us-section .back-bg{
    background-color: #F3F6FF;
    padding: 30px;
    border-radius: 7px;
}
.join-us-section .back-bg .border-right{
    border-right: 2px solid #ccc;
}
.join-us-section .back-bg .first-sec .join-us-title{
    font-family: Poppins;
    font-weight: 700;
    font-size: 20px;
    color: #000;
}
.join-us-section .back-bg .first-sec .sub-text{
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    color: #000;
}
.join-us-section .back-bg .first-sec .link-text{
    font-weight: 700;
    text-decoration: underline;
    font-size: 14px;
    color: #000;     
    margin-right: 25px;
}
.join-us-section .back-bg .first-sec .btn {
    padding: 0;
}
.join-us-section .back-bg .first-sec .download-icon{
    width: 15px;
    margin-left: 10px;
}
.join-us-section .back-bg .first-sec .upload-img{
    width: 141px;
    height: auto;
}


@media (min-width:768px) and (max-width:991px){
    .join-us-section .back-bg .first-sec .sub-text {
        font-size: 12px;
}

.join-us-section .back-bg .first-sec .upload-img {
    width: 100px;
}
}



@media (min-width:992px) and (max-width:1023px){
    .join-us-section .back-bg .first-sec .sub-text {
        font-size: 12px;
}

.join-us-section .back-bg .first-sec .upload-img {
    width: 100px;
}
}


@media (min-width:1024px) and (max-width:1199px){
.join-us-section .back-bg .first-sec .upload-img {
    width: 110px;
}
}

@media (min-width:1200px) and (max-width:1440px){
.join-us-section .back-bg .first-sec .upload-img {
    width: 131px;
    height: auto;
}
}