#banner-body {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  top: -90px;
}

#banner-body:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#e3f1ff, #ffffff);
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
  transform: scaleX(2);
}

.home-banner .banner {
  color: #260645;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  max-width: 100%;
}

.home-banner .banner .banner-swiper {
  padding-bottom: 2%;
}

.home-banner .banner .banner-swiper .swiper-button-next,
.home-banner .banner .banner-swiper .swiper-button-prev {
  position: absolute;
  bottom: 60px;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.home-banner .banner .banner-swiper .swiper-button-next, .home-banner .banner .banner-swiper .swiper-rtl .s.home-banner .banner .banner-swiper .swiper-button-next, .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-prev {
  right: 47%;
  left: auto;
  top: 96%;
}

.home-banner .banner .banner-swiper .swiper-button-prev, .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-next {
  left: 49%;
  right: auto;
  top: 96%;
}

.home-banner .banner .banner-swiper .swiper-button-next:after, .home-banner .banner .banner-swiper .swiper-button-prev:after {
  font-size: 9px;
  color: #ffffff;
  width: 30px;
  background: linear-gradient(to top, #b615cf 0%, #4d0d54 100%);
  height: 27px;
  /* text-align: center; */
  /* padding: 10px; */
  border-radius: 50px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-banner .banner .banner-swiper .swiper-pagination {
  display: none;
}

@media (min-width: 768px) and (max-width: 991px) {

  .home-banner .banner .banner-swiper .swiper-button-next:after, .home-banner .banner .banner-swiper .swiper-button-prev:after {
    width: 25px;
    height: 25px;
}
  .home-banner .banner .banner-swiper .swiper-button-next, .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-prev {
    right: 46%;
}
.home-banner .banner .banner-swiper .swiper-button-prev, .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-next {
  left: 47%;
}
  .main .media .media-content .media-swiper .swiper-button-prev,
  .main .media .media-content .media-swiper .swiper-rtl .swiper-button-next {
    right: 12%;
  }
  .home-banner .banner .banner-swiper .swiper-button-next, .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-prev {
    top: 96%;
}
.home-banner .banner .banner-swiper .swiper-button-prev, .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-next {
  top: 96%;
}
}

@media (min-width: 992px) and (max-width: 1023px) {
  .home-banner .banner .banner-swiper .swiper-button-next,
  .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-prev {
    right: 44%;
  }
  .main .media .media-content .media-swiper .swiper-button-prev,
  .main .media .media-content .media-swiper .swiper-rtl .swiper-button-next {
    right: 12%;
  }
  .home-banner .banner .banner-swiper .swiper-button-next, .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-prev {
    top: 96%;
}
.home-banner .banner .banner-swiper .swiper-button-prev, .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-next {
  top: 96%;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .home-banner .banner .banner-swiper .swiper-button-next, .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-prev {
    right: 45%;
}
  .main .media .media-content .media-swiper .swiper-button-prev,
  .main .media .media-content .media-swiper .swiper-rtl .swiper-button-next {
    right: 12%;
  }
  .home-banner .banner .banner-swiper .swiper-button-next, .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-prev {
    top: 96%;
}
.home-banner .banner .banner-swiper .swiper-button-prev, .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-next {
  top: 96%;
}
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .home-banner .banner .banner-swiper .swiper-button-next,
  .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-prev {
    right: 46%;
    top: 96%;
  }
  .home-banner .banner .banner-swiper .swiper-button-prev,
  .home-banner .banner .banner-swiper .swiper-rtl .swiper-button-next {
    left: 49%;
    top: 96%;
  }
}
