.Sitetour-section {
  margin-top: 5%;
  background-image: url("/public/assets/image/our-people/site_tour/bg_img.png");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2% 0%;
}


.Sitetour-section .text-holder h1 {
  font-size: 34px;
  font-weight: 600;
  color: #000000;
}

.Sitetour-section .text-holder p {
  font-size: 15px;
  font-weight: 500;
  color: #2a2a2a;
}

.Sitetour-section .slider-content .img-holder .site-tour-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: 0.3s;
}

.Sitetour-section .slider-content .img-holder .site-tour-img:hover {
  transform: scale(1.1);
}

.Sitetour-section .slider-content .img-holder {
  margin: 5px;
  border-radius: 10px;
  overflow: hidden;
}

.Sitetour-section .site-tour-swiper {
    padding-bottom: 10%;
}

.Sitetour-section .site-tour-swiper .swiper-button-next:after,
.Sitetour-section .site-tour-swiper .swiper-button-prev:after {
  display: none;
}

.Sitetour-section
  .site-tour-swiper
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #562278;
  width: 10px;
  height: 10px;
}


.Sitetour-section
  .site-tour-swiper
  .swiper-pagination-bullet{
  background: #00A7E5;
  opacity:  1;
}


.Sitetour-section
  .site-tour-swiper .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  display: flex;
  justify-content: center;
  align-items: center;
}



@media (min-width:768px) and (max-width:1200px){
  .Sitetour-section .text-holder h1 {
    font-size: 30px;
  }
  .Sitetour-section .text-holder p {
    font-size: 14px;
  }
  
  .Sitetour-section {
    margin-bottom: 10%;
}
}

@media (min-width:1400px) and (max-width:1499px){
.section-common-title .sec-title {
  font-size: 20px;
}
}