.Licence {
    margin-top: 5%;
    margin-bottom: 10%;
    position: relative;
}

.Licence .Licence-bg-img {
    width: 100%;
    height: 100%;
}

.Licence .licence-content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin-top: 3%;
}

.Licence .licence-content .section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}
.Licence .licence-content  .section-title h1 {
    font-size: 34px;
    font-weight: 600;
    color: #000000;
}

.Licence .licence-content .licence-imgs {
    margin-top: 3%;
}

.Licence .licence-content .licence-imgs .img-holder .licence-image {
    width: 100%;
    height: 100%;
}

.Licence .licence-content .licence-imgs .img-holder {
    box-shadow: 0px 15px 25px -3px #56227845;
}

@media(min-width:768px) and (max-width:991px){
    .Licence .licence-content  .section-title h1 {
        font-size: 30px;
        font-weight: 600;
        color: #000000;
    }
}
