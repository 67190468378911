.Mediacontacts {
    margin-top: 5%;
    margin-bottom: 10%;
}
.Mediacontacts .blue-bg {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E6F8FF 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    padding-bottom: 50%;
}
.Mediacontacts .contact-text-holder .media-contact-heading h1 {
  text-align: left;
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}
.Mediacontacts .contact-text-holder .media-contact-heading h2 {
    text-align: left;
    font-size: 53px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 0;
}

.Mediacontacts .contact-text-holder {
    background: #CFF1FE 0% 0% no-repeat padding-box;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5% 15%;
}

.Mediacontacts .contact-text-holder .number-mail-holder .contact {
    display: flex;
    justify-content: normal;
    align-items: center;
    width: 100%;
    margin-bottom: 14px;
}


.Mediacontacts .contact-text-holder .number-mail-holder .contact .icon {
    background: #f8f9fa;
    text-align: center;
    border-radius: 50px;
    border: 1px solid #f8f9fa;
    display: flex;
    padding: 10px;
    margin-right: 15px;
}

.contact .icon .contact-icon {
    color: #562278;
    font-size: 15px;
}

.contact p {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 0px;
}

@media(min-width:768px) and (max-width:991px){
.Mediacontacts .contact-text-holder .media-contact-heading h1 {
    font-size: 20px;
}
.Mediacontacts .contact-text-holder .media-contact-heading h2 {
    font-size: 30px;
}
.contact p {
    font-size: 15px;
}
.Mediacontacts .contact-text-holder .number-mail-holder .contact .icon {
    padding: 5px;
}
.contact .icon .contact-icon {
    font-size: 11px;
}

.Mediacontacts .contact-text-holder {
    border-radius: 30px;
}
}

@media(min-width:992px) and (max-width:1023px){
    .Mediacontacts .contact-text-holder {
        border-radius: 30px;
    }
    .Mediacontacts .contact-text-holder .media-contact-heading h2 {
        font-size: 40px;
    }
    
    .Mediacontacts .contact-text-holder .media-contact-heading h1 {
        font-size: 30px;
    }
    }

@media(min-width:1024px) and (max-width:1199px){

    
    .Mediacontacts .contact-text-holder {
        border-radius: 40px;
        padding: 3% 14%;
    }
    .Mediacontacts .contact-text-holder .media-contact-heading h2 {
        font-size: 30px;
    }

    .contact p {
        font-size: 15px;
    }

    .Mediacontacts .contact-text-holder .number-mail-holder .contact .icon {
        padding: 6px;
    }

    .contact .icon .contact-icon {
        font-size: 12px;
    }

.Mediacontacts .contact-text-holder .media-contact-heading h1 {
    font-size: 25px;
}
}