@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Livvic:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* #root{
  overflow-x: hidden;
} */
body {
  margin: 0px;
  padding: 0px;
  font-family: "Livvic", "sans-serif" !important;
}

.title {
  font-family: "Poppins", "sans-serif" !important;
}

#center-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-holder {
  margin-bottom: 2%;
}

.form-control:focus {
  box-shadow: none !important;
}
.form-check-input {
  box-shadow: none !important;
}
.btn-holder .read-more {
  width: 36px;
  height: 36px;
  background: #ffffff;
  border-radius: 50px;
  border: none;
  padding: 0;
  font-size: 15px;
  color: #000000;
}

.btn-holder .btn-primary {
  --bs-btn-active-bg: none;
}

.btn-holder .read-more:hover {
  background-color: #562278;
}

.btn-holder .read-more:hover .read-more-btn {
  margin-left: 9px;
  color: #f3f8ff;
  transition: 0.3s ease-in;
}

/* .btn-holder .read-more:hover .right{
  filter: brightness(10);
  margin-left: 4px;
  color: #000000; 
  transition: 0.3s ease-in;
} */

/* transperent btn css start */

.btn-holder .trans-read-more {
  width: 36px;
  height: 36px;
  background: none;
  border-radius: 50px;
  border: 1px solid #00000059;
  padding: 0;
  font-size: 15px;
  color: #000000;
}
.btn-holder .trans-read-more:hover {
  color: #9926e5;
}

.btn-holder .trans-read-more:hover {
  background: none;
  border-color: #9926e5;
  --bs-btn-active-bg: none;
}

.btn-holder .trans-read-more:hover .trans-read-more-btn {
  margin-left: 9px;
  color: #9926e5;
  transition: 0.3s ease-in;
}
/* 
.btn-holder .trans-read-more:hover .right{
  margin-left: 4px;
  color: #000000; 
  transition: 0.3s ease-in;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 8px;
} */

/* transperent-btn css start */

.section-top-heading {
  position: relative;
}

#career-heading {
  font-size: 22px;
  font-weight: 700;
  color: #000000;
}

.section-top-heading .header-bg .vision-bg-img {
  width: 100%;
  height: 100%;
}

.section-top-heading .heading-title {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 0px 0px rgb(217 213 213 / 15%);
}

.section-top-heading .heading-title h1 {
  color: #233f8c;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
}

.section-common-title .sec-title {
  font-family: Livvic;
  font-weight: 500;
  font-size: 18px;
  color: #2a2a2a;
  text-align: center;
  margin: 50px auto;
  position: relative;
  width: fit-content;
}

.section-common-title .sec-title::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 50px;
  background: #5b5b5b;
  left: -65px;
  top: 12px;
}

.section-common-title .sec-title::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 50px;
  background: #5b5b5b;
  right: -65px;
  top: 12px;
}

/* start members-card-div style  */
.members-card-div {
  width: 100%;
  height: 290px;
  width: 275px;
  height: 310px;
  padding: 15px;
  margin-bottom: 3%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.07);
  text-align: center;
  position: relative;
}

.leader-card-sec {
  display: flex;
  justify-content: center;
  align-items: center;
}
.members-card-div .profile-img-div {
  width: 126px;
  height: 126px;
  border-radius: 100%;
  margin: auto;
  overflow: hidden;
  box-sizing: border-box;
}

.members-card-div .profile-img-div .member-img {
  width: 100%;
  transition: 0.3s;
}
.members-card-div .profile-img-div:hover .member-img {
  transform: scale(1.1);
  transition: 0.3s;
}
.members-card-div .member-name {
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  color: #2a2a2a;
  margin: 15px 0px 5px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.members-card-div .member-position {
  font-weight: normal;
  font-size: 14px;
  color: #2a2a2a;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.members-card-div .icon-position {
  position: absolute;
  bottom: 20px;
  /* left: 45%; */
}

/* end members-card-div style  */

/* google map css start */
.google-map {
  padding-bottom: 5%;
}
/* google map css end */

/* purple read more btn css start */
.purple-read-more .on-hover {
  background: none;
  border: none;
  border-radius: 19px;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  text-align: justify;
  border: 1px solid #562278;
  box-shadow: 0px 1px 5px 0px #9926e5;
  padding: 5px 20px;
  font-family: "Poppins";
  padding: 5px 20px;
}

.purple-read-more .on-hover:hover {
  background: #562278;
  border: 1px solid #562278;
  border-radius: 19px;
  font-weight: 500;
  color: #ffffff;
}

.purple-read-more .purple-read-more-btn {
  background: #562278;
  border: none;
  border-radius: 19px;
  font-size: 12px;
  font-weight: 300;
  color: #ffffff;
  text-align: justify;
  border: 1px solid #562278;
  box-shadow: 0px 1px 5px 0px #9926e5;
  padding: 5px 20px;
  font-family: "Poppins";
  padding: 5px 20px;
}

.purple-read-more #no-shadow {
  box-shadow: none;
}

.purple-read-more .purple-read-more-btn:hover {
  background: #ffffff;
  border: 1px solid #562278;
  border-radius: 19px;
  font-weight: 500;
  color: #000000;
}

/* purple read more btn css end */

/* download btn css start */
.Download {
  position: absolute;
  bottom: 25px;
}

.Download .Download-btn {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  background: #ffffff;
  border-radius: 50px;
  border: none;
  font-family: "Poppins";
}

.Download .Download-btn:hover {
  background-color: purple;
  color: #ffffff;
  border: none;
}

.Download #no-hover:hover {
  background-color: #ffffff;
  color: #000000;
  border: none;
}

/* download btn css end */

/* offcanvas css start */

.impact-offcanvas .offcanvas-body .impact-modal .text-holder p {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-align: justify;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 18;
  -webkit-box-orient: vertical;
}

.impact-offcanvas .offcanvas-body .impact-modal .text-holder {
  margin: 10% 3%;
  padding-right: 5%;
  height: 100%;
}

.impact-offcanvas .offcanvas-body .impact-modal .text-holder h1 {
  font-size: 27px;
  font-weight: 600;
  color: #2a2a2a;
  margin-bottom: 2%;
}

.impact-offcanvas
  .offcanvas-body
  .impact-modal
  .img-div
  .slider-content
  .img-holder
  .site-tour-img {
  width: 100%;
  height: 100%;
  border-radius: 0px;
}

#black-close {
  filter: unset;
  opacity: 1;
}

.impact-offcanvas .offcanvas-body .impact-modal .slider-content .img-holder {
  top: -5px;
}
.impact-offcanvas.offcanvas.offcanvas-top.show {
  height: 100%;
}

.impact-offcanvas .offcanvas-body {
  position: absolute;
  padding: 0;
  overflow: unset;
}

.impact-offcanvas .offcanvas-header {
  justify-content: end;
  z-index: 1;
  position: absolute;
  top: 3%;
  right: 3%;
}

.impact-offcanvas .bg-img-holder .offcanvas-bg-img {
  width: 100%;
  height: 100%;
}

.top-offcanvas {
  position: relative;
}
.impact-offcanvas .bg-img-holder {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
}
/* offcanvas css end */

/* media and news img css start */
.main-img {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5%;
}

.main-img .swiper-main-img {
  width: 100%;
  height: 344px;
  transition: 0.3s;
}

.main-img:hover .swiper-main-img {
  transform: scale(1.2);
}

.main-img .overlay {
  background-image: linear-gradient(
    180deg,
    rgb(0 0 0 / 45%) 0%,
    #00000066 100%
  );
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  border-radius: 5px;
}

.main-img .overlay .main-text-holder {
  display: flex;
  justify-content: space-between;
  align-items: end;
  height: 82%;
  margin: auto 5%;
}

.main-img .overlay .main-text-holder .text-holder h1 {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
}

.main-img .overlay .main-text-holder .text-holder p {
  font-size: 12px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 0;
}

.overlay .date-holder {
  background: #ffffff36 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 0.8;
  width: 80px;
  margin-bottom: 0;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3%;
  margin-top: 3%;
}

.main-img .overlay .date-holder p {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 0;
  color: #ffffff;
}

#protfolio-card {
  box-shadow: none;
  border-radius: 8px;
  background: #f9fbff;
}

/* media and news img css end */

/* modal css start */
.same-modal .modal-body .malaria-vdo {
  width: 100%;
  height: 100%;
}

.same-modal .modal-content {
  background: none;
  border: none;
}
.same-modal .modal-header {
  border-bottom: none;
}

.same-modal .modal-img {
  width: 100%;
  height: 100%;
}

.same-modal .modal-header .btn-close {
  filter: invert(1);
  opacity: 1;
}
/* modal css end */

.underline {
  color: #000;
  text-decoration: underline;
}

/* heading responsive start */
@media (min-width: 768px) and (max-width: 991px) {
  .impact-offcanvas .offcanvas-body .impact-modal .text-holder p {
    font-size: 14px;
  }
  .section-top-heading .heading-title h1 {
    font-size: 17px;
  }

  .section-top-heading .heading-title #career-heading {
    font-size: 17px;
  }

  .impact-offcanvas .offcanvas-body .impact-modal .text-holder h1 {
    font-size: 25px;
  }

  .Download .Download-btn {
    font-size: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .section-top-heading .heading-title h1 {
    font-size: 17px;
  }

  .section-top-heading .heading-title #career-heading {
    font-size: 17px;
  }
}
/* heading responsive end */

@media (min-width: 1024px) and (max-width: 1199px) {
  .impact-offcanvas .offcanvas-body .impact-modal .text-holder p {
    font-size: 13px;
  }

  .Download .Download-btn {
    font-size: 10px;
  }
}
