.FandQ {
  padding: 5% 0% 10% 0%;
}

.FandQ .blue-bg {
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #E6F8FF 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 50%;
}

.FandQ .f-q-content .report-heading  {
  margin-bottom: 5%;
}

.FandQ .f-q-content .report-heading h1 {
  text-align: left;
  font-size: 26px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}

.FandQ .f-q-content .report-heading h2 {
  text-align: left;
  font-size: 53px;
  font-weight: 600;
  margin-bottom: 10%;
  color: #000000;
}

.FandQ .f-q-content .report-heading h3 {
  text-align: left;
  font-size: 28px;
  font-weight: 500;
  color: #562278;
}



.FandQ .f-q-content .quetions-box {
    background: #FFFFFF;
    box-shadow: 0px 0px 24px #00000017;
    border-radius: 10px;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3%;
}

.FandQ .f-q-content .quetions-box .f-Q {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
}


.FandQ .f-q-content .quetions-box .year-holder h1{
    font-size: 56px;
    font-weight: 700;
    color: #F0F4FF;
}



.FandQ .f-q-content .quetions-box .quetion-holder h1{
    font-size: 16px;
    font-weight: 500;
    color: #2A2A2A;
}

.FandQ .f-q-content .quetions-box .purple-read-more #btn-size{
    padding: 5px 25px;
    box-shadow: none;
}
@media (min-width:768px) and (max-width:991px){
  .FandQ .f-q-content .report-heading h2 {
    font-size: 34px;
    text-align: center;
    margin-bottom: 3%;
  }
  .FandQ .f-q-content .report-heading h1 {
    font-size: 25px;
    text-align: center;
}

.FandQ .f-q-content .report-heading h3 {
  text-align: center;
  font-size: 25px;
}


.FandQ .f-q-content .quetions-box .year-holder h1 {
  font-size: 30px;
}
  }

@media (min-width:992px) and (max-width:1023px){

  .FandQ .f-q-content .quetions-box .quetion-holder h1 {
    font-size: 14px;
}

  .FandQ .f-q-content .quetions-box .f-Q {
    width: 70%;
}

  .FandQ .f-q-content .quetions-box .year-holder h1 {
    font-size: 30px;
}
.FandQ .f-q-content .report-heading h2 {
  font-size: 30px;
}
.FandQ .f-q-content .report-heading h1 {
  font-size: 22px;
}
}

@media (min-width:1024px) and (max-width:1399px){

  .FandQ .f-q-content .report-heading h3 {
    font-size: 25px;
}

  .FandQ .f-q-content .quetions-box .quetion-holder h1 {
    font-size: 14px;
}

  .FandQ .f-q-content .quetions-box .f-Q {
    width: 70%;
}

  .FandQ .f-q-content .quetions-box .year-holder h1 {
    font-size: 30px;
}
  .FandQ .f-q-content .report-heading h2 {
    font-size: 35px;
}
.FandQ .f-q-content .report-heading h1 {
  font-size: 20px;
}
  }