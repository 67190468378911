.Governance {
    position: relative;
    /* margin-top: 5%; */
    margin-bottom: 10%;
}
.Governance .main-content-holder .report-heading h1 {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 0px;
  }
  .Governance .main-content-holder .report-heading h2 {
    text-align: center;
    font-size: 53px;
    font-weight: 600;
    color: #000000;
  }

  .Governance .Governance-bg .Governance-bg-img{
    width: 100%;
    height: 100%;
  }

  .Governance .main-content-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 5%;
}

.Governance .main-content-holder .performance-cards-content {
    margin-top: 3%;
}

.Governance .main-content-holder .performance-cards-content .cards-holder .slider-img-holder .Governance-img {
    width: 100%;
    height: 100%;
    transition: 0.3s;
}

.Governance .main-content-holder .performance-cards-content .cards-holder .slider-img-holder:hover .Governance-img{
    transform: scale(1.1);
}

.Governance .main-content-holder .performance-cards-content .cards-holder .Governance-swiper .swiper-button-next:after,
.Governance .main-content-holder .performance-cards-content .cards-holder .Governance-swiper .swiper-button-prev:after {
    display: none;
}


.Governance .main-content-holder .performance-cards-content .cards-holder .Governance-swiper {
    padding-bottom: 5%;
}

.Governance .main-content-holder .performance-cards-content .cards-holder .slider-img-holder {
    position: relative;
    margin: 20px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 6px 0 10px -4px #999999b8, -6px 0 10px -4px #999999b8;
}

.Governance .main-content-holder .performance-cards-content .cards-holder .Governance-swiper 
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: purple;
}

.Governance .main-content-holder .performance-cards-content .cards-holder .slider-img-holder #governance-download-btn {
    position: absolute;
    bottom: 6%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    display: none;
}

.Governance .main-content-holder .performance-cards-content .cards-holder .slider-img-holder .overlay {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 0%;
    background: transparent linear-gradient(180deg,#56227800,#562278bf 50%,#562278); ;
    transition: 0.4s;
}

.Governance .main-content-holder .performance-cards-content .cards-holder .slider-img-holder:hover #governance-download-btn{
    display: block;
    transition: 0.3s;
}


.Governance .main-content-holder .performance-cards-content .cards-holder .slider-img-holder:hover .overlay{
    height: 45%;
    transition: 0.4s;
    
}

.Governance .main-content-holder .performance-cards-content .cards-holder .slider-img-holder .hr {
    position: absolute;
    bottom: 0;
    border-bottom: 1px solid #00000059;
    width: 90%;
    left: 5%;
}
 @media (min-width:768px) and (max-width:991px){
     .Governance .main-content-holder .report-heading h1 {
         font-size: 20px;
     }
     .Governance .main-content-holder .report-heading h2 {
        font-size: 30px;
    }

    .Governance .main-content-holder .performance-cards-content {
        margin-top: -2%;
    }

    .Governance .main-content-holder .performance-cards-content .cards-holder .Governance-swiper {
        padding-bottom: 3%;
    }

    .Governance .main-content-holder .performance-cards-content .cards-holder .slider-img-holder {
        margin: 20px 10px;
    }
 }

 @media (min-width:992px) and (max-width:1023px){
    .Governance .main-content-holder .report-heading h1 {
        font-size: 20px;
    }
    .Governance .main-content-holder .report-heading h2 {
       font-size: 30px;
   }
   .Governance .main-content-holder .performance-cards-content {
    margin-top: -2%;
}


}


@media (min-width:1024px) and (max-width:1199px){
    .Governance .main-content-holder .performance-cards-content {
        margin-top: 0%;
    }

    .Governance .main-content-holder .report-heading h1 {
        font-size: 25px;
    }
    .Governance .main-content-holder .report-heading h2 {
       font-size: 40px;
   }
}


