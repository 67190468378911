.awards-rewards-section {
    width: 100%;
    height: 100%;
    background-image: url(http://localhost:3001/static/media/wall-bg.b0850daf3e7117b30618.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0px 0px 0px 0px;
}

.awards-rewards-section .troffi-sec .img-div .award-image{
    width: 100%;
    height: auto;
}
.awards-rewards-section .troffi-sec {
    position: relative;
    padding-top: 5%;
}

.awards-rewards-section .troffi-sec .img-div {
    margin-bottom: 20%;
}

@media (min-width:768px) and (max-width:991px){
.awards-rewards-section {
    margin-bottom: 10%;
}
}

@media (min-width:992px) and (max-width:1023px){
    .awards-rewards-section {
        margin-bottom: 10%;
    }
    }

    @media (min-width:1024px) and (max-width:1199px){
        .awards-rewards-section {
            margin-bottom: 8%;
        }
        }