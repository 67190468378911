.Financialreports {
  background: transparent linear-gradient(180deg, #ffffff 0%, #e6f8ff 100%) 0%
    0% no-repeat padding-box;
  padding-bottom: 3%;
}

.Financialreports .report-heading {
  margin: 5% 0%;
}

.Financialreports .report-heading h1 {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}
.Financialreports .report-heading h2 {
  text-align: center;
  font-size: 53px;
  font-weight: 600;
  color: #000000;
}

.finance-content .finance-data-cards .data-box {
  background: #008fc4;
  border-radius: 10px;
  padding: 30px 0px 75px 25px;
  margin-bottom: 17px;
  height: 85%;
  width: 97%;
  /* width: 335px; */
  position: relative;
}

.finance-content .finance-data-cards .data-box .date-holder p {
  font-size: 10px;
  font-weight: 600;
  color: #000000;
  padding: 2px;
}

.Financialreports .finance-content .finance-data-cards .data-box .date-holder {
  width: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 0% 1%;
}

.Financialreports
  .finance-content
  .finance-data-cards
  .data-box
  .finance-text-holder
  p {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin-top: 5%;
}

.Financialreports .finance-content .financial-swiper .swiper-button-next:after,
.Financialreports .finance-content .financial-swiper .swiper-button-prev:after {
  display: none;
}

.Financialreports .finance-content .financial-swiper {
  padding-bottom: 5%;
}

.Financialreports
  .finance-content
  .financial-swiper
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #562278;
  width: 10px;
height: 10px;
}

.Financialreports
  .finance-content
  .financial-swiper
  .swiper-pagination-bullet {
  background: #00A7E5;
  opacity:  1;
}

.Financialreports
  .finance-content
  .financial-swiper .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .Financialreports .report-heading h1 {
    font-size: 24px;
}

  .Financialreports .report-heading h2 {
    font-size: 40px;
  }

  .Financialreports {
    margin-bottom: 10%;
  }
  .Financialreports
  .finance-content
  .finance-data-cards
  .data-box
  .finance-text-holder
  p {
    font-size: 14px;
}
}

@media (min-width: 991px) and (max-width: 1023px) {
  .Financialreports .report-heading h1 {
    font-size: 24px;
}

  .Financialreports .report-heading h2 {
    font-size: 40px;
  }

  .Financialreports {
    margin-bottom: 10%;
  }
  .Financialreports
  .finance-content
  .finance-data-cards
  .data-box
  .finance-text-holder
  p {
    font-size: 14px;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Financialreports .report-heading h1 {
    font-size: 24px;
}

  .Financialreports .report-heading h2 {
    font-size: 40px;
  }

  .Financialreports {
    margin-bottom: 10%;
  }

  .Financialreports
  .finance-content
  .finance-data-cards
  .data-box
  .finance-text-holder
  p {
    font-size: 14px;
}
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .Financialreports .report-heading h1 {
    font-size: 24px;
}

  .Financialreports .report-heading h2 {
    font-size: 40px;
  }

  .Financialreports {
    margin-bottom: 5%;
  }

  .Financialreports
  .finance-content
  .finance-data-cards
  .data-box
  .finance-text-holder
  p {
    font-size: 14px;
}
}
@media (min-width: 1400px) and (max-width: 1499px) {
  .Financialreports
  .finance-content
  .finance-data-cards
  .data-box
  .finance-text-holder
  p {
    font-size: 14px;
}
}

