.Hivaids{
    margin-bottom: 5%;
}

.Hivaids .heading p {
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 0;
}

.Hivaids .heading{
    background: #00A7E5 ;
    border-radius: 50px;
    padding: 5px 16px;
    width: fit-content;
    margin: 0px auto;
}

/* HIV /AIDS section start */
.Hivaids .main-holder {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E5F8FF 100%) 0% 0% no-repeat padding-box;
    padding: 5% 0%;
}

.Hivaids .main-holder .main-content-holder {
    margin-top: 5%;
}
.Hivaids .main-holder .main-content-holder .sub-heading-holder .sub-heading {
    background: #00A7E5;
    border-radius: 50px;
    padding: 2px 16px;
    width: fit-content;
    margin-bottom: 5%;
}
.Hivaids .main-holder .main-content-holder .sub-heading-holder .sub-heading p {
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 0;
}


.Hivaids .main-holder .main-content-holder .sub-heading-holder h1{
    font-size: 34px;
    font-weight: 600;
    color: #000000;
    /* position: relative; */
}

.Hivaids .main-holder .main-content-holder .sub-heading-holder {
    position: relative;
    height: 100%;
}


/* left side css start */



.Hivaids .main-holder .main-content-holder .sub-heading-holder .border-dashed {
    border-right: 0;
    border-style: dashed;
    border-width: thin;
    border-left: thin;
    color: #00a8ff;
    content: "";
    display: block;
    height: 48%;
    left: 25%;
    position: absolute;
    top: 29%;
    width: 0;
}

.Hivaids .main-holder .main-content-holder .sub-heading-holder #left::before {
    display: block;
    content: "";
    position: absolute;
    top: 76%;
    left: 95%;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 100%;
    border: 1.5px solid #00a8ff;
    background: #00a8ff;
}

.Hivaids .main-holder .main-content-holder .sub-heading-holder #left:after {
    border-style: dashed;
    border-bottom: 1px;
    border-width: thin;
    border-left: thin;
    color: #00a8ff;
    content: "";
    display: block;
    height: 0;
    left: 25%;
    position: absolute;
    top: 77%;
    width: 70%;
}
/* left side css end */

/* right side css end */
.Hivaids .main-holder .main-content-holder .sub-heading-holder #right::before {
    display: block;
    content: "";
    position: absolute;
    top: 78%;
    left: -18%;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 100%;
    border: 1.5px solid #00a8ff;
    background: #00a8ff;
}

.Hivaids .main-holder .main-content-holder .sub-heading-holder #right:after {
    content: "";
    display: block;
    height: 0px;
    width: 55%;
    position: absolute;
    left: -15%;
    top: 79%;
    border-style: dashed;
    border-bottom: 1px;
    color: #00a8ff;
    border-width: thin;
    border-left: thin;
}

.Hivaids .main-holder .main-content-holder .sub-heading-holder .border-dashed-2 {
    display: block;
    content: "";
    position: absolute;
    width: 0px;
    border-right: 0px;
    border-style: dashed;
    height: 53%;
    right: 60%;
    color: #00a8ff;
    top: 26%;
    border-width: thin;
    border-left: thin;
}
/* right side css end */


.Hivaids .main-holder .main-content-holder .text-content-holder {
    padding-right: 10%;
}

.Hivaids .main-holder .main-content-holder .text-content-holder p {
    font-size: 15px;
    font-weight: 500;
    color: #2A2A2A;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
}

.Hivaids .main-holder .main-content-holder .main-img-div .img-holder .infectives-img{
    width: 100%;
    height: 100%;
    transition: 0.3s;
}

.Hivaids .main-holder .main-content-holder .main-img-div .vdo-holder{
    border-radius: 50%;
}

.Hivaids .main-holder .main-content-holder .main-img-div .vdo-holder .malaria-vdo{
width: 100%;
height: 100%;
border-radius: 50%;
}

.Hivaids .main-holder .main-content-holder .main-img-div .img-holder .infectives-img:hover{
transform: scale(1.1);
}

.Hivaids .main-holder .main-content-holder .main-img-div .img-holder{
    border-radius: 50%;
    overflow: hidden;
}

.Hivaids .main-holder .main-content-holder .main-img-div {
    margin-top: 20%;
}

/* HIV /AIDS section end */



@media (min-width:768px) and (max-width:991px){
    .Hivaids .main-holder .main-content-holder .sub-heading-holder h1 {
        font-size: 20px;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder .border-dashed-2 {
        height: 55%;
    }
    .Hivaids .main-holder .main-content-holder .text-content-holder p {
        font-size: 16px;
        line-height: 1.5;
        -webkit-line-clamp: 7;
    }
    .Hivaids .main-holder .main-content-holder .main-img-div {
        margin-top: 25%;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder .border-dashed {
        height: 209px;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder #left::before {
        top: 82%;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder #left:after {
        top: 84%;
    }
    .Hivaids {
        margin-bottom: 10%;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder .border-dashed {
        height: 212px;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder #right:after {
        top: 81%;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder #right::before {
        top: 79%;
    }
}

@media (min-width:991px) and (max-width:1023px){
    .Hivaids .main-holder .main-content-holder .sub-heading-holder h1 {
        font-size: 20px;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder #left:after {
        top: 87%;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder #left::before {
        top: 86%;
    }
    .Hivaids {
        margin-bottom: 10%;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder .border-dashed-2 {
        height: 305px;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder #right:after {
        top: 82%;
    }

    .Hivaids .main-holder .main-content-holder .sub-heading-holder #right::before {
        top: 80%;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder .border-dashed {
        height: 313px;
    }

    .Hivaids .main-holder .main-content-holder .main-img-div {
        margin-top: 31%;
    }
}

@media (min-width:1024px) and (max-width:1199px){
    .Hivaids .main-holder .main-content-holder .sub-heading-holder h1 {
        font-size: 27px;
    }

    .Hivaids .main-holder .main-content-holder .sub-heading-holder .border-dashed {
        height: 53%;
    }

    .Hivaids .main-holder .main-content-holder .sub-heading-holder .border-dashed-2 {
        height: 55%;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder #left:after {
        top: 82%;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder #left::before {
        top: 81%;
    }
    .Hivaids {
        margin-bottom: 10%;
    }
    
    .Hivaids .main-holder .main-content-holder .sub-heading-holder #right:after {
        top: 81%;
    }

    .Hivaids .main-holder .main-content-holder .sub-heading-holder #right::before {
        top: 80%;
    }

 
}

@media (min-width:1200px) and (max-width:1399px){
    .Hivaids .main-holder .main-content-holder .sub-heading-holder h1 {
        font-size: 27px;
    }

    .Hivaids .main-holder .main-content-holder .sub-heading-holder .border-dashed-2 {
        height: 55%;
    }
    .Hivaids .main-holder .main-content-holder .sub-heading-holder .border-dashed {
        height: 315px;
    }

    .Hivaids .main-holder .main-content-holder .sub-heading-holder #left:after {
        top: 83%;
    }
  
    .Hivaids .main-holder .main-content-holder .sub-heading-holder #left::before {
        top: 82%;
    }

    .Hivaids {
        margin-bottom: 10%;
    }
        
    .Hivaids .main-holder .main-content-holder .sub-heading-holder #right:after {
        top: 81%;
    }

    .Hivaids .main-holder .main-content-holder .sub-heading-holder #right::before {
        top: 80%;
    }

    .Hivaids .main-holder .main-content-holder .main-img-div {
        margin-top: 27%;
    }

}

.main-malaria-vdo-holder{
    position: relative;
}
.main-malaria-vdo-holder .malaria-vdo-play-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
}


