.news {
  position: relative;
  margin-bottom: 10%;
  background-image: url(/public/assets/image/newsroom/news/new_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 1% 0% 4% 0%;
}

/* .news .news-bg .news-bg-img {
  width: 100%;
  height: 100%;
} */

.news .news-heading {
    margin: 5% 0%;
  }
  
  .news .news-heading h1 {
    text-align: center;
    font-size: 31px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 0px;
}

  .news .new-slider-content .news-swiper .swiper-button-next:after, .news .new-slider-content .news-swiper  .swiper-button-prev:after {
    display: none;
  }

  .news .new-slider-content .news-swiper 
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: purple;
  width: 10px;
  height: 10px;
}


.news .new-slider-content .news-swiper 
  .swiper-pagination-bullet{
  background: #00A7E5;
  opacity:  1;
}


.news .new-slider-content .news-swiper #gap {
  margin: 0px 20px;
}

/* .news .news-card {
  position: absolute;
  width: 100%;
  top: 0;
} */

.news .new-slider-content .news-swiper {
  padding-bottom: 7%;
}


@media (min-width:1024px)  and (max-width:1199px){
  .news .new-slider-content .news-swiper {
    padding-bottom: 8%;
}
}
