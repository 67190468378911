.opportunity-section {
  padding: 5% 0px;
  margin-bottom: 5%;
}

.opportunity-section .butn {
  position: absolute;
  bottom: 0%;
}
.opportunity-section .butn #btn-size {
  padding: 3px 25px;
  box-shadow: none;
}

.opportunity-section .section-1 {
  padding-right: 10%;
  position: relative;
  height: 100%;
}

.opportunity-section .section-2 .group-img {
  width: 100%;
}
.opportunity-section .section-1 .working-p {
  font-size: 28px;
  color: #000;
  font-weight: 500;
  margin-bottom: 0px;
}
.opportunity-section .section-1 .cipla-title {
  font-family: Livvic;
  font-weight: 600;
  font-size: 53px;
  color: #000;
  margin-bottom: 20px;
}
.opportunity-section .section-1 .content {
  font-weight: 500;
  font-size: 15px;
  color: #000;
}

.career-oppo-bg-section {
  background-image: url("/public/assets/image/career/career-opo-bg.png");
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.career-oppo-bg-section .two-side-cards {
  background-color: #fff;
  /* padding: 30px; */
  border-radius: 7px;
  position: relative;
  top: 45px;
  box-shadow: 0px 0px 5px 0px #cccc;
  height: 400px;
}
.career-oppo-bg-section .two-side-cards .career-title {
  font-weight: 600;
  font-size: 32px;
  color: #000;
  margin-bottom: 20px;
}
.career-oppo-bg-section .two-side-cards .content-p {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    line-height: 27px;
    text-align: justify;
}
.career-oppo-bg-section .two-side-cards .content-div {
  padding: 30px;
  position: relative;
}
.career-oppo-bg-section .bd-right {
  position: relative;
}

.career-oppo-bg-section .two-side-cards .bd-right::after {
    position: absolute;
    content: "";
    right: -8%;
    width: 1px;
    height: 300px;
    background-color: #ccc;
}

.career-oppo-bg-section .two-side-cards .link-color {
  font-weight: normal;
  font-size: 14px;
  color: #00a7e5;
}

.job-rol-section {
  width: 100%;
  padding-top: 10%;
  background: linear-gradient(180deg, #fff 0%, #e5f8ff 100%);
  margin-bottom: 10%;
  padding-bottom: 5%;
}
.job-rol-section .job-rol-card {
  background-color: #00a7e5;
  border-radius: 5px;
  padding: 20px;
}
.job-rol-section .job-rol-card .pdf-img {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.job-rol-section .job-rol-card .job-p-role {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  margin-bottom: 0px;
  margin-top: 10px;
}
.job-rol-section .job-rol-card .view-pdf-main {
  position: relative;
  width: 100%;
  height: 100%;
}
.job-rol-section .job-rol-card .view-pdf-main .view-pdf-p {
  position: absolute;
  bottom: 0px;
  right: 0px;
  text-decoration: underline;
  color: #fff;
  font-size: 14px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .opportunity-section .butn {
    bottom: -15%;
  }
  .career-oppo-bg-section {
    height: 100%;
  }

  .career-oppo-bg-section .two-side-cards {
    height: 100%;
  }
  .career-oppo-bg-section .two-side-cards .career-title {
    font-size: 25px;
  }
  .career-oppo-bg-section .two-side-cards .content-p {
    font-size: 12px;
    line-height: 25px;
  }
  .opportunity-section .section-1 .working-p {
    font-size: 20px;
  }
  .opportunity-section .section-1 .cipla-title {
    font-size: 30px;
  }
  .opportunity-section .section-1 .content {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .opportunity-section .butn {
    bottom: -15%;
  }
  .career-oppo-bg-section {
    height: 100%;
  }

  .career-oppo-bg-section .two-side-cards {
    height: 100%;
  }
  .career-oppo-bg-section .two-side-cards .career-title {
    font-size: 20px;
  }
  .career-oppo-bg-section .two-side-cards .content-p {
    font-size: 12px;
    line-height: 25px;
  }
  .opportunity-section .section-1 .working-p {
    font-size: 20px;
  }
  .opportunity-section .section-1 .cipla-title {
    font-size: 30px;
  }
  .opportunity-section .section-1 .content {
    font-size: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .career-oppo-bg-section .two-side-cards .career-title {
    font-size: 20px;
  }
  .opportunity-section .section-1 .cipla-title {
    font-size: 30px;
  }

  .opportunity-section .section-1 .working-p {
    font-size: 20px;
  }

  .opportunity-section .section-1 .content {
    font-size: 14px;
}

.opportunity-section .butn {
  bottom: -10%;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {


  .career-oppo-bg-section .two-side-cards .career-title {
    font-size: 20px;
  }

  .opportunity-section .section-1 .content {
    font-size: 13px;
  }
  .opportunity-section .butn {
    bottom: -10%;
  }

  .opportunity-section .section-1 .cipla-title {
    font-size: 40px;
  }

  .opportunity-section .section-1 .working-p {
    font-size: 20px;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .career-oppo-bg-section {
    height: 100%;
  }

  .career-oppo-bg-section .two-side-cards {
    height: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .career-oppo-bg-section {
    height: 100%;
  }
  .career-oppo-bg-section .two-side-cards {
    height: 100%;
  }

  .opportunity-section .section-1 .cipla-title {
    font-size: 30px;
}
.opportunity-section .section-1 .working-p {
  font-size: 25px;
}

.opportunity-section .butn {
  bottom: -10%;
}

.career-oppo-bg-section .two-side-cards .career-title {
  font-size: 30px;
}
}
