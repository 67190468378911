@import url('https://fonts.googleapis.com/css2?family=Arial:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Times New Roman:wght@400;500;600;700;800;900&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', 'sans-serif' !important;
}
body a{
    text-decoration: none;
}
