.NewsInner {
  margin-bottom: 10%;
}

.NewsInner .NewsInner-content-holder .img-holder .news-inner-img {
  width: 100%;
  height: 100%;
}

.NewsInner .NewsInner-content-holder .inner-div {
  margin-top: 5%;
}

.NewsInner .NewsInner-content-holder .text-content-holder {
  margin-bottom: 5%;
}

.NewsInner .NewsInner-content-holder .text-content-holder p {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.NewsInner .NewsInner-content-holder {
  margin-top: 2%;
  background: #ffffff;
  box-shadow: 0px 0px 14px #00000012;
  border-radius: 10px;
  padding: 30px;
}

.NewsInner .back {
  margin-top: 2%;
}

.NewsInner .back p {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}

.NewsInner .NewsInner-content-holder .newsinner-heading-content h1 {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

.NewsInner .NewsInner-content-holder .date-and-share-holder {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin: 1% 0px;
}

.NewsInner .NewsInner-content-holder .date-and-share-holder .date-holder {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 1%;
}

.NewsInner .NewsInner-content-holder .date-and-share-holder .date-holder p {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0;
}

.NewsInner
  .NewsInner-content-holder
  .date-and-share-holder
  .date-holder
  .calender {
    margin-right: 10px;
    font-size: 13px;
}

.NewsInner .NewsInner-content-holder .date-and-share-holder .share {
  margin: 0% 1%;
}

.NewsInner .NewsInner-content-holder .update-text p {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0;
}

.NewsInner .NewsInner-content-holder .update-text {
  background: #eff3ff;
  border-radius: 50px;
  width: fit-content;
  padding: 2px 5px;
}

/* latest company css start */
.NewsInner .latest-company {
  margin-top: 5%;
}

.NewsInner .latest-company .company-heading-holder {
  margin-bottom: 3%;
}
.NewsInner .latest-company .company-heading-holder h2 {
  font-size: 28px;
  font-weight: 500;
  color: #000000;
}
.NewsInner .latest-company .company-heading-holder h1 {
  font-size: 50px;
  font-weight: 600;
  color: #000000;
}

.NewsInner .latest-company .company-info{
  margin-bottom: 0%;
}

.NewsInner .latest-company .company-info .card-holder .img-holder {
  border-radius: 10px;
  overflow: hidden;
}

.NewsInner .latest-company .company-info .card-holder .img-holder .company-img {
  width: 100%;
  height: 100%;
  transition: 0.3s;
}
.NewsInner .latest-company .company-info .card-holder .img-holder:hover .company-img{
transform: scale(1.1);
    transition: 0.3s;
}



.NewsInner .latest-company .company-info .card-holder .text-holder h1 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.16px;
  color: #2a2a2a;
}

.NewsInner .latest-company .company-info .card-holder .date-holder {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 1%;
}

.NewsInner .latest-company .company-info .card-holder .date-holder p {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0;
}

.NewsInner .latest-company .company-info .card-holder .date-holder .calender {
  margin-right: 10px;
}

.NewsInner .latest-company .company-info .card-holder .date-holder button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: none;
  font-size: none;
  line-height: none;
  border: none;
  background: none;
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}

.NewsInner .date-font {
  font-size: 12px;
  font-weight: 500;
}

.NewsInner .latest-company .company-info .card-holder .date-holder {
  margin: 5% 0%;
}

.NewsInner .latest-company .company-info .card-holder {
  background: #f3f6ff;
  border-radius: 10px;
  padding: 2% 4%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NewsInner .latest-company .company-info .card-holder .btn-holder {
  position: absolute;
  bottom: 10%;
}

/* latest company css end */

@media (min-width: 768px) and (max-width: 991px) {

  .NewsInner .latest-company .company-info{
    margin-bottom: 5%;
}
  .NewsInner .latest-company .company-heading-holder h1 {
    font-size: 30px;
  }
  
  .NewsInner .latest-company .company-heading-holder h2 {
    font-size: 20px;
  }
  
  .NewsInner .latest-company .company-info .card-holder {
    padding: 2% 4%;
    margin-bottom: 5%;
}
}

@media (min-width: 992px) and (max-width: 1023px) {

  .NewsInner .latest-company .company-info{
    margin-bottom: 5%;
}
  .NewsInner .latest-company .company-heading-holder h1 {
    font-size: 30px;
  }
  
  .NewsInner .latest-company .company-heading-holder h2 {
    font-size: 20px;
  }
  
  .NewsInner .latest-company .company-info .card-holder {
    padding: 2% 4%;
    height: 160%;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {

  .NewsInner .latest-company .company-info{
    margin-bottom: 5%;
}

  .NewsInner .latest-company .company-heading-holder h1 {
    font-size: 30px;
  }
  
  .NewsInner .latest-company .company-heading-holder h2 {
    font-size: 20px;
  }
  
  .NewsInner .latest-company .company-info .card-holder {
    padding: 2% 4%;
    height: 160%;
}
}

@media (min-width: 1200px) and (max-width: 1399px) {
.NewsInner .latest-company .company-heading-holder h1 {
  font-size: 30px;
}

.NewsInner .latest-company .company-heading-holder h2 {
  font-size: 20px;
}

.NewsInner .latest-company .company-info .card-holder .btn-holder {
  bottom: 0%;
}
}