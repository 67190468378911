.main .vision .vision-content {
  padding: 5% 0%;
}

.main .vision .lifelong {
  margin-bottom: 0%;
}

.main .vision .vision-content .img-holder .vision-img-1 {
  width: 100%;
  height: 100%;
}

.main .vision .vision-content .vision-text-holder {
  margin-top: 15%;
  padding-right: 15%;
}

.main .vision .vision-content .vision-text-holder .text-heading h1 {
  font-size: 32px;
  font-weight: 500;
  color: #000000;
}

.main .vision .vision-content .vision-text-holder .text-heading p {
  font-size: 15px;
  font-weight: 500;
  color: #2a2a2a;
}

.main .vision .vision-content .vision-text-holder .text-heading span {
  font-size: 15px;
  font-weight: 500;
  color: #233f8c;
  position: relative;
}

.main .vision .vision-content .vision-text-holder .text-heading span::before {
  display: block;
  content: "";
  position: absolute;
  top: 145px;
  left: 145%;
  right: 0;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 100%;
  border: 1.5px solid #00a8ff;
  background: #00a8ff;
}

.main
  .vision
  .vision-content
  .vision-text-holder
  .text-heading
  span
  .border-dashed {
    display: block;
    content: "";
    position: absolute;
    /* width: 1px; */
    border-right: 1px;
    border-style: dashed;
    height: 130px;
    left: 150%;
    top: 12px;
    color: #00a8ff;
    border-width: thin;
    border-left: thin;
}

.main
  .vision
  .vision-content
  .vision-text-holder
  .text-heading
  span .arrow{
    font-size: 11px;
    margin:0px 5px;
  }

  .main .vision .vision-content .vision-text-holder .text-heading span:after {
    content: "";
    display: block;
    height: 0px;
    width: 50%;
    position: absolute;
    left: 100%;
    top: 10px;
    border-style: dashed;
    border-bottom: 1px;
    border-width: thin;
    color: #00a8ff;
}

.main .vision .vision-content .vision-img-bg {
  /* position: relative; */
  margin-top: 10%;
}
.main .vision .vision-content .vision-img-bg .bg-img-holder .vision-bg-img {
  width: 100%;
}
.main .vision .vision-content .img-holder .vision-img:hover {
  transform: scale(1.1);
}

.main .vision .vision-content .img-holder .vision-img {
  width: 100%;
  transition: 0.3s;
}

/* .main .vision .vision-content .vision-img-bg .img-holder {
  border-radius: 30% 70% 67% 33% / 30% 30% 70% 70%;
  position: absolute;
  top: 15%;
  overflow: hidden;
} */

/* vdo section start */
.main .vision .vision-content .vdo-box {
  margin-top: 5%;
  margin-bottom: 5%;
}

.main .vision .vision-content .vdo-box .vdo {
  background: linear-gradient(180deg,#000000,#13001e) 0 0 no-repeat padding-box;
  border-radius: 18px 18px 18px 18px;
  height: 100%;
  opacity: 1;
  padding: 5% 0;
  width: 100%;
}

.main .vision .vision-content .vdo-box .vdo .who-we-are-vdo  {
  width: 100%;
  height: 100%;
}
/* vdo section end */


.main .vision .vision-content .vision-mission-culture {
  position: relative;
  margin-top: 5%;
}

.main .vision .vision-content .vision-mission-culture .vision-mission-culture-bg-img .vision-mission-bg-img{
  width: 100%;
  height: 100%;
}


.main .vision .vision-content .vision-mission-culture .vision-mission-cards {
  position: absolute;
  top: 38%;
  left: 0;
  right: 0;
}

.main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card .card {
  background: #FFFFFF;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 304px;
  padding: 20px;
  border-bottom: 2px solid #00A7E5;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 15px 22px -15px #00a7e569;
}

.main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card {
  margin: 0% 5%;
}

.main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card .card .card-body {
  padding: 20px 0px;
}

.main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card .card .card-body .card-title.h5 {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #2A2A2A;
}

.main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card .card .card-body p.card-text {
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  color: #2A2A2A;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}





@media (min-width:768px) and (max-width:991px){

  .main .vision .vision-content .vision-text-holder .text-heading h1 {
    font-size: 25px;
}

.main .vision .vision-content .vision-text-holder .text-heading p {
  font-size: 13px;
}

.main
  .vision
  .vision-content
  .vision-text-holder
  .text-heading
  span
  .border-dashed {
    height: 60px;
}

.main .vision .vision-content .vision-text-holder .text-heading span::before {
  top: 72px;
  left: 147%;
  width: 11px;
  height: 11px;
}

.main .vision .vision-content .vdo-box .vdo {
  height: 100%;
}

.main .vision .vision-content .vision-mission-culture {
  position: relative;
  margin-top: 10%;
  margin-bottom: 5%;
}

.main .vision .vision-content .vision-mission-culture .vision-mission-cards {
  top: 30%;
}
  
  .main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card {
    margin: 0% 5%;
  }
  
  .main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card .card {
    padding: 15px;
    height: 185px;
}

  .main .vision .lifelong {
    margin-bottom: 5%;
  }
  

  .main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card .card .card-body p.card-text {
    font-size: 11px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    height: 39px;
    -webkit-box-orient: vertical;
    max-height: 3.6em;
    line-height: 1.2;
}

  }

@media (min-width:992px) and (max-width:1023px){
  .main .vision .vision-content .vision-mission-culture .vision-mission-cards {
    top: 10%;
}
  
  .main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card {
    margin: 0% 0%;
  }
  
  .main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card .card {
    padding: 15px;
  }
  
  .main .vision .lifelong {
    margin-bottom: 12%;
}
  
  .main .vision .vision-content .vdo-box .vdo {
    height: 100%;
  }

  .main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card .card .card-body p.card-text {
    font-size: 13px;
}

.main .vision .vision-content {
  margin-bottom: 10%;
}
  
  }


@media (min-width:1024px) and (max-width:1199px){
  .main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card .card .card-body p.card-text {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.main .vision .vision-content .vision-mission-culture {
  margin-top: 5%;
  margin-bottom: 5%;
}
.main .vision .vision-content .vision-mission-culture .vision-mission-cards {
  top: 15%;
}

.main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card {
  margin: 0% 0%;
}

.main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card .card {
  padding: 15px;
  height: 270px;
}

.main .vision .lifelong {
  margin-bottom: 12%;
}

.main .vision .vision-content .vdo-box .vdo {
  height: 100%;
}

}

@media (min-width:1200px) and (max-width:1399px){

 
  .main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card {
    margin: 0% 2%;
}
.main .vision .vision-content .vision-mission-culture {
  margin-top: 5%;
  margin-bottom: 5%;
}
.main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card .card .card-body p.card-text {
  font-size: 13px;
}
.main .vision .vision-content .vision-mission-culture .vision-mission-cards {
  top: 35%;
}

.main .vision .vision-content .vision-mission-culture .vision-mission-cards .vision-card .card {
  height: 280px;
}
}

@media (min-width:1400px) and (max-width:1440px){
.main .vision .vision-content .vision-mission-culture .vision-mission-cards {
  top: 30%;
}
}