.main .Foundingdirector .main-founder-section {
    position: relative;
}

.main .Foundingdirector .founder-bg .founder-bg-img {
    width: 100%;
    height: 135%;
}

.main .Foundingdirector .founder-card-content {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding-bottom: 10%;
}


.main .Foundingdirector .main-founder-section .founder-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

