.Annualreports {
    margin-top: 5%;
    margin-bottom: 10%;
}

.Annualreports .annual-reports-content .report-heading{
    margin-top: 20%;
}

.Annualreports .annual-reports-content .report-heading h1 {
  text-align: left;
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}
.Annualreports .annual-reports-content .report-heading h2 {
  text-align: left;
  font-size: 53px;
  font-weight: 600;
  color: #000000;
}

.Annualreports .annual-reports-content .data-table {
    width: 100%;
    border-bottom: 2px solid #00000047;
    border-left: 2px solid #00000054;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #ECFAFF 100%) ;
}

.Annualreports .annual-reports-content .data-table .data-box {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 3% 0%;
}

.Annualreports .annual-reports-content .data-table .box{
    position: relative;
}

.Annualreports .annual-reports-content .data-table .data-box .box .dashed-box {
    border-right: 1px solid;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-style: dashed;
    border-left: none;
    border-left-style: none;
    width: 86px;
    height: 74px;
}

.Annualreports .annual-reports-content .data-table .data-box .box:after {
    content: "";
    border-bottom: 1px solid #00000069;
    position: absolute;
    width: 300px;
    top: 50%;
    left: 100%;
}

.Annualreports .annual-reports-content .years-and-data-holder {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
}

.Annualreports .annual-reports-content .years-and-data-holder .years  .year-holder {
    width: 86px;
    height: 74px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 25% 0%;
}

.Annualreports .annual-reports-content .years-and-data-holder .years  .year-holder h1 {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.Annualreports .annual-reports-content .data-table .data-box .data-title {
    position: absolute;
    left: 65%;
}

.Annualreports .annual-reports-content .data-table .data-box .butn {
    position: absolute;
    left: 85%;
}
.Annualreports .annual-reports-content .data-table .data-box .data-title p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    color: #000000;
}

.Annualreports .annual-reports-content .data-table .data-box .butn #btn-size {
    padding: 5px 20px;
    box-shadow: none;
}

@media (min-width:768px) and (max-width:991px){
    .Annualreports .annual-reports-content .report-heading{
        margin-top: 5%;
    }
    .Annualreports .annual-reports-content .data-table .data-box .box:after {
        width: 140px;
    }
    .Annualreports .annual-reports-content .data-table .data-box .data-title {
        left: 59%;
    }

    .Annualreports .annual-reports-content .report-heading h1 {
        text-align: center;
        font-size: 25px;
    }

    .Annualreports .annual-reports-content .report-heading h2 {
        text-align: center;
        font-size: 40px;
    }
    .Annualreports .annual-reports-content .years-and-data-holder .years  .year-holder {
        margin: 15% 0%;
    }
}

@media (min-width:992px) and (max-width:1023px){

    .Annualreports .annual-reports-content .report-heading{
        margin-top: 30%;
    }
    .Annualreports .annual-reports-content .data-table .data-box .box:after {
        width: 185px;
    }

    .Annualreports .annual-reports-content .report-heading h1 {
        text-align: left;
        font-size: 25px;
    }

    .Annualreports .annual-reports-content .report-heading h2 {
        text-align: left;
        font-size: 40px;
    }

    .Annualreports .annual-reports-content .data-table .data-box .data-title p {
        font-size: 15px;
    }

    .Annualreports .annual-reports-content .years-and-data-holder .years  .year-holder {
        margin: 20% 0%;
    }
}

@media (min-width:1024px) and (max-width:1199px){
    .Annualreports .annual-reports-content .report-heading{
        margin-top: 30%;
    }
    .Annualreports .annual-reports-content .data-table .data-box .box:after {
        width: 185px;
    }

    .Annualreports .annual-reports-content .report-heading h1 {
        text-align: left;
        font-size: 25px;
    }

    .Annualreports .annual-reports-content .report-heading h2 {
        text-align: left;
        font-size: 40px;
    }

    .Annualreports .annual-reports-content .data-table .data-box .data-title p {
        font-size: 15px;
    }

    .Annualreports .annual-reports-content .years-and-data-holder .years  .year-holder {
        margin: 20% 0%;
    }
}

@media (min-width:1200px) and (max-width:1399px){
    .Annualreports .annual-reports-content .data-table .data-box .box:after {
        width: 235px;
    }
    .Annualreports .annual-reports-content .report-heading h1 {
        text-align: left;
        font-size: 25px;
    }

    .Annualreports .annual-reports-content .report-heading h2 {
        text-align: left;
        font-size: 40px;
    }

    .Annualreports .annual-reports-content .data-table .data-box .data-title p {
        font-size: 18px;
    }

    .Annualreports .annual-reports-content .data-table .data-box .data-title {
        left: 63%;
    }
}

