.main .Contact .Contact-content {
    padding: 5% 0%;
}
.main .Contact{
  position: relative;
}

.main .Contact .Contact-content .blue-bg {
  position: absolute;
  top: 0;
  z-index: -1;
}

.main .Contact .Contact-content .blue-bg .contact-bg-img{
width: 100%;
height: 100%;
}

.main .Contact .heading {
    padding: 1%;
    background: #e3f1ff94;
}

.main .Contact .heading h1 {
  font-size: 24px;
  font-weight: 600;
  color: #233f8c;
  text-align: center;
}

.main .Contact .Contact-content .img-holder {
  border-radius: 10px;
  margin-bottom: 5%;
  padding: 0px 20px;
}

.main .Contact .Contact-content .img-holder .contact-img-1 {
  width: 100%;
  height: 100%;
}

.main .Contact .Contact-content .contact-text-holder .text-heading h1 {
  font-size: 32px;
  font-weight: 600;
  color: #000000;
}

.main .Contact .Contact-content .contact-text-holder .contact-card .list-dots {
  list-style: none;
}

.main
  .Contact
  .Contact-content
  .contact-text-holder
  .contact-card
  .list-padding {
    padding-left: 0rem;
    margin-bottom: 0rem;
}

.main .Contact .Contact-content .contact-text-holder .text-heading p {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.main
  .Contact
  .Contact-content
  .contact-text-holder
  .contact-card
  .card-heading
  h1 {
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 20px;
}

.main .Contact .Contact-content .contact-text-holder .contact-card {
    background: #F3F6FF;
    padding: 12px 10px;
    border-radius: 10px;
    margin-bottom: 5%;
}
.main
  .Contact
  .Contact-content
  .contact-text-holder
  .contact-card
  .contact-icon {
    display: flex;
    margin-bottom: 15px;
}
.main
  .Contact
  .Contact-content
  .contact-text-holder
  .contact-card
  .contact-icon
  .icon {
    margin-right: 5%;
}
.main
  .Contact
  .Contact-content
  .contact-text-holder
  .contact-card
  .contact-icon
  .icon {
    background: #562278;
    height: 26px;
    width: 26px;
    text-align: center;
    border-radius: 50px;
    display: flex;
    border: 1px solid #e0e2e7;
    align-items: center;
    justify-content: center;
}

/* .main
  .Contact
  .Contact-content
  .contact-text-holder {
  position: relative;
  height: 100%;
}

.main
  .Contact
  .Contact-content
  .contact-text-holder
 .social {
  position: absolute;
  bottom: 5%;
} */

.main
  .Contact
  .Contact-content
  .contact-text-holder
  .contact-card
  .contact-icon
  .icon
  svg {
  color: #ffffff;
  font-size: 10px;
  margin-bottom: 0px;
}

.main
  .Contact
  .Contact-content
  .contact-text-holder
  .contact-card
  .contact-icon p {
    font-size: 14px;
  font-weight: 500;
    color: #000000;
    margin-bottom: 0;
}


.main
  .Contact
  .Contact-content
  .contact-text-holder .social-heading h1 {
    font-size: 14px;
  font-weight: 600;
    color: #000000;
}

.main
  .Contact
  .Contact-content
  .contact-text-holder .social .social-icons {
    display: flex;
    justify-content: start;
  }
  
  .main
  .Contact
  .Contact-content
  .contact-text-holder .social .social-icons .icon {
    margin-right: 2%;
}
  
  .main
  .Contact
  .Contact-content
  .contact-text-holder .social .social-icons .icon .social-icon-img {
    width: 26px;
    height: 26px;
}


  
@media (min-width:768px) and (max-width:991px){

  .main
  .Contact
  .Contact-content
  .contact-text-holder
  .contact-card
  .contact-icon
  .icon {
    height: 20px;
    width: 20px;
}

.main
  .Contact
  .Contact-content
  .contact-text-holder
  .contact-card
  .contact-icon
  .icon
  svg {
    font-size: 7px;
}
  .main
  .Contact
  .Contact-content
  .contact-text-holder
  .contact-card
  .card-heading
  h1 {
    margin-bottom: 7px;
}
.main
  .Contact
  .Contact-content
  .contact-text-holder
  .contact-card
  .contact-icon {
    margin-bottom: 2px;
}

.main .Contact .Contact-content .contact-text-holder .text-heading p {
  font-size: 12px;
}

.main .Contact .Contact-content .contact-text-holder .text-heading h1 {
  font-size: 20px;
}

}

@media (min-width:992px) and (max-width:1023px){
.main .Contact .Contact-content .contact-text-holder .text-heading h1 {
  font-size: 25px;
}

.main .Contact .Contact-content .img-holder {
  border-radius: 10px;
  margin-bottom: 5%;
  padding: 0;
}
}

@media (min-width:1024px) and (max-width:1199px){
  .main .Contact .Contact-content .img-holder {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
}