.Library {
  margin-top: 5%;
  position: relative;
}

.Library .blue-bg {
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #E6F8FF 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 70%;
}

.Library .main-holder .Library-heading h1 {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}

.Library .main-holder {
  background: #ffffff;
  box-shadow: 0px 0px 37px #00000014;
  border-radius: 40px;
  padding: 5% 5%;
}

.Library .main-holder .content-holder .library-tabs.nav.nav-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.Library .main-holder .content-holder .library-tabs.nav.nav-link {
  border: none;
}

.Library .main-holder .content-holder .library-tabs .nav-item {
    margin: 0px 15px;
}

.Library .main-holder .content-holder .library-tabs .nav-item button {
    font-size: 16px;
    font-weight: 500;
    font-family: "poppins";
    color: #000000;
    border-radius: 50px;
    padding: 10px 30px;
    border: 1px solid;
}
.Library .main-holder .content-holder .library-tabs button.active {
  font-size: 16px;
  font-weight: 600;
  font-family: "poppins";
  box-shadow: 0px 3px 6px #00000029;
  color: #562278;
  background: none;
  border: none;
  background-color: #E1E8FF ;
}

.Library .main-holder .content-holder .library-tabs button:focus-visible {
  border: none;
}

.Library .main-holder .content-holder {
  margin-top: 5%;
}

.Library .main-holder .content-holder .gallery-img-holder .photo-gallery-img {
  width: 100%;
  height: 100%;
}
.Library .main-holder .content-holder .gallery-img-holder {
  margin-bottom: 10%;
}

.Library .main-holder .content-holder .library-swiper .swiper-button-next,
.Library
  .main-holder
  .content-holder
  .library-swiper
  .swiper-rtl
  .swiper-button-prev {
  display: none;
}

.Library
  .main-holder
  .content-holder
  .library-swiper
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: purple;
}

.Library
  .main-holder
  .content-holder
  .library-swiper {
    padding-bottom: 5%;
    padding-top: 5%;
}

/* video gallery css start */

.Library .main-holder .content-holder .main-img-vdo-holder{
    position: relative;
}
.Library .main-holder .content-holder .main-img-vdo-holder .play-btn-holder {
    position: absolute;
    z-index: 999999;
    top: 50%;
    left: 50%;
}

.Library .main-holder .content-holder .main-img-vdo-holder .play-btn-holder .vdo-play-btn {
    font-size: 40px;
    color: #f8f9faa3;
    border-radius: 50px;
}
/* video gallery css end */

/* podcast-tab-css start */
.Library .main-holder .content-holder .main-podcast-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 13px #00000014;
    border-radius: 10px;
    padding: 10px 16px;
    margin: 0px 0px 7% 5px;
}
.Library .main-holder .content-holder .main-podcast-holder .podcast-img-holder .podcast-img {
    width: 30px;
    height: 33px;
}
.Library .main-holder .content-holder .main-podcast-holder  .podcast-img-holder {
    padding: 20px;
    background: #FFECEE ;
    border-radius: 50px;
}

.Library .main-holder .content-holder .main-podcast-holder .text-holder {
  margin-right: 20%;
  margin-left: 5%;
}

.Library .main-holder .content-holder .main-podcast-holder .text-holder p{
    margin-bottom: 0;
font-size: 16px;
font-weight: 600;
color: #000000;
}

.Library .main-holder .content-holder .main-podcast-holder .podcast-play-btn-holder .podcast-vdo-play-btn {
    font-size: 30px;
    color: #C8C8C8 ;
    border-radius: 50px;
}

/* podcast-tab-css end */