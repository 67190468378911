.step-form-sec .form-b-div {
  text-align: center;
}

.step-form-sec .form-b-div .form-b-title {
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  color: #562278;
  margin-top: 10px;
}
.step-form-sec .form-b-div .sub-t {
  font-family: Poppins;
  font-weight: normal;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.step-form-sec .form-b-div .sub-t .back {
  margin-right: 5px;
  font-size: 13px;
}

.sub-title {
  display: flex;
}





/* academic step form css end */

.step-form-sec .formm-sec {
  background-color: #fff;
  padding: 25px 100px 100px 100px;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  border-radius: 10px;
  position: relative;
  box-shadow: -8px 0px 5px -2px rgba(224, 188, 224, 0.56);
  margin-bottom: 3%;
}

.step-form-sec #bottom-space {
  margin-bottom: 5%;
}

.step-form-sec #language-width {
  width: 30%;
}
.step-form-sec .formm-sec .form-group {
  margin-bottom: 25px;
}

.step-form-sec .formm-sec .form-group .gender-flex .checkbox {
  display: flex;
}
.step-form-sec .formm-sec .form-group .form-control {
  font-weight: normal;
  font-size: 14px;
  color: #919191;
  border-radius: 0px;
  border: 0px;
  border-bottom: 2px solid #909090;
  padding: 5px 0px;
  font-family: "Poppins", "sans-serif";
  width: 100%;
}

.step-form-sec .formm-sec .form-group #checkbox-mb {
  margin-bottom: 1%;
}

.step-form-sec .formm-sec .form-group .gender-flex .type {
  margin-right: 20px;
}



.step-form-sec .formm-sec .form-group .gender-flex #full-width {
  width: 100%;
}

.step-form-sec .formm-sec .form-group .end {
  display: flex;
  justify-content: end;
}

.step-form-sec .formm-sec .form-group .gender-flex .date-text {
  width: 100px;
  margin-top: 10px;
}

.step-form-sec .formm-sec .form-group .form-check-input:checked {
  background-color: #000000;
  border-color: #000000;
}
.step-form-sec .formm-sec .form-group .form-label span {
  font-weight: normal;
  font-size: 14px;
  color: #919191;
  padding: 5px 0px;
  font-family: "Poppins", "sans-serif";
  width: 100%;
}

.step-form-sec .formm-sec .form-group textarea {
  font-weight: normal;
  font-size: 14px;
  color: #919191;
  border-radius: 0px;
  border: 0px;
  border-bottom: 2px solid #909090;
  padding: 5px 0px;
  font-family: "Poppins", "sans-serif";
  width: 100%;
}

.step-form-sec .formm-sec .form-group #underline {
  text-decoration: underline;
}

.step-form-sec .formm-sec .form-group #underline:hover {
  color: purple;
}

.step-form-sec .formm-sec .form-group .form-label {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  font-family: "Poppins", "sans-serif";
}

.step-form-sec .formm-sec .form-group .form-check-label {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  font-family: "Poppins", "sans-serif";
}

.step-form-sec .formm-sec .form-group .space-between {
  display: flex;
  justify-content: space-between;
}

.step-all-secc {
  position: relative;
}

.formm-sec .note {
  background: #f7ecff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 18px 11px;
  margin-top: 20px;
}

.formm-sec .note p {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #131313;
  margin-bottom: 0;
}

.formm-sec .note span {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #131313;
}

.step-form-sec-2 #bottom-space-2 {
  margin-bottom: 3%;
  padding-top: 9%;
}

.step-form-sec .step {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: indigo;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}



/* changes code css */

.career-step.nav.nav-tabs {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  top: 20px;
}

.career-step .nav-item button {
  height: 89px;
  background: #ffffff;
}

.career-step.nav-tabs .nav-link {
border-top-left-radius: 30px;
border-top-right-radius: 30px;
color: #00000069;
  font-size: 18px;
  opacity: 1;
  font-family: "Poppins";
  font-weight: 600;
}

.career-step.nav-tabs .nav-link.active {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  background: #f3f6ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  opacity: 1;
}


.step-all-secc .tab-content .fade {
  transition: none;
}

#nav-next-btn {
  position: absolute;
  left: 51%;
  bottom: 75px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(86, 34, 120);
  font-weight: 500;
  text-align: left;
  color: rgb(6, 6, 6);
  font-size: 15px;
  padding: 3px 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#nav-next-btn:hover {
background-color: #562278;
color: #f8f9fa;
}

#nav-back-btn {
  position: absolute;
  left: 38%;
  bottom: 75px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(86, 34, 120);
  font-weight: 500;
  font-size: 15px;
  padding: 3px 40px;
  text-align: left;
  color: rgb(6, 6, 6);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#nav-back-btn:hover {
  background-color: #562278;
  color: #f8f9fa;
  }



.form-navigation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

#nav-submit-btn {
  background-color: #fff;
  border: 1px solid #562278;
  border-radius: 50px;
  bottom: 70px;
  color: #060606;
  font-size: 15px;
  font-weight: 500;
  padding: 3px 40px;
  position: absolute;
  text-align: left;
}

#nav-submit-btn:hover {
  background-color: #562278;
  color: #f8f9fa;
}

.select-dropdown {
  font-weight: normal;
  font-size: 14px;
  color: #919191;
  border-radius: 0px;
  border: 0px;
  border-bottom: 2px solid #909090;
  padding: 3px 10px;
  font-family: "Poppins", "sans-serif";
  margin-left: 5px;
}

.success-modal .modal-content {
  width: 453px;
  height: 219px;
  background: #562278;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 29px;
}

.success-modal .modal-content .modal-body {
  text-align: center;
}

.success-modal .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-modal .modal-content .modal-body p {
  font-size: 15px;
  font-weight: 500;
  color: #ffe923;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.success-modal .modal-content .modal-body span {
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
}

.career-step.nav-tabs.nav-fill .nav-item .nav-link {
  width: 269px;
  height: 90px;
  padding-bottom: 25px;
}

.career-step.nav-tabs .nav-link {
  border: none;
}




@media (min-width: 768px) and (max-width: 991px) {

  .success-modal .modal-content {
    width: 335px;
    height: 180px;
}

  .career-step.nav.nav-tabs {
    top: 20px;
}
  .step-form-sec .formm-sec {
    padding: 5% 5% 10% 5%;
}

.career-step.nav-tabs.nav-fill .nav-item .nav-link {
  width: 174px;
  font-size: 15px;
  padding: 5px;
}

#nav-submit-btn {
  bottom: 35px;
  font-size: 12px;
  padding: 5px 35px;
}

.RSPBprogressBar {
  top: 35px;
}

#nav-back-btn {
  left: 35%;
  bottom: 50px;
  font-size: 12px;
  padding: 5px 35px;
}

#nav-next-btn {
  left: 52%;
  bottom: 50px;
  font-size: 12px;
  padding: 5px 35px;
}
}

@media (min-width: 992px) and (max-width: 1023px) {

  .success-modal .modal-content {
    width: 335px;
    height: 180px;
}

  .career-step.nav.nav-tabs {
    top: 20px;
}

  #nav-submit-btn {
    font-size: 12px;
    padding: 5px 35px;
    bottom: 50px;

}
  
  .step-form-sec .formm-sec {
    padding: 10% 5%;
}

.career-step.nav-tabs.nav-fill .nav-item .nav-link {
  width: 194px;
  font-size: 15px;
}

#nav-next-btn {
  font-size: 12px;
  padding: 5px 35px;
}

#nav-back-btn {
  font-size: 12px;
  padding: 5px 35px;
  left: 36%;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {


  #nav-submit-btn {
    bottom: 50px;
    font-size: 12px;
    padding: 5px 35px;
}

#nav-back-btn {
  left: 37%;
  font-size: 12px;
  padding: 5px 35px;
}

#nav-next-btn {
  font-size: 12px;
  padding: 5px 35px;
}

  .step-form-sec .formm-sec {
    padding: 5% 2% 9% 2%;
}

.career-step.nav-tabs.nav-fill .nav-item .nav-link {
  width: 194px;
  font-size: 15px;
}
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .career-step.nav-tabs.nav-fill .nav-item .nav-link {
    width: 231px;
    font-size: 16px;
}
}

